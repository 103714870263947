/**
 * 수기 입력 데이터 중, 언어에 상관업이 공통으로 표시되어야 할 데이터
 */
var RecruitCommonTextMeta = /** @class */ (function () {
    function RecruitCommonTextMeta() {
        this.id = 0;
        this.recruit_id = 0;
        this.meta = {
            use_deadline: false,
            closed_at: '',
            company_name: '',
            is_consigned_company: false,
            company_address1: '',
            company_address2: '',
            contact_name: '',
            contact_phone: '',
            contact_mail: '',
            work_payment: 0,
            action_phone: '',
            use_action_phone: false,
            action_site: '',
            use_action_site: false,
            action_email: '',
            use_action_email: false,
        };
        // this.meta.closed_at = moment;
    }
    return RecruitCommonTextMeta;
}());
export { RecruitCommonTextMeta };
