var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import ApiService from '@/ts/core/services/ApiService';
import { startSpinner } from '@/ts/modules/startSpinner';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ref, onMounted, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserPhoto } from '../../ts/UserPhoto';
import moment from 'moment';
import { UserFile } from '@/ts/UserFile';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ApplicationPage',
    props: {
        mgmtCode: {
            required: true,
            type: String,
        },
        password: {
            type: String,
            default: '',
        },
    },
    setup: function (__props) {
        var _this = this;
        var langHeader = document.head.querySelector('meta[name="App-Language"]');
        var locale = langHeader.content;
        var localMessages = ref({});
        var setLocaleMessage = useI18n().setLocaleMessage;
        var downloadHost = process.env.MIX_DOWNLOAD_HOST;
        var props = __props;
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadPresetAndLocale()];
                    case 1:
                        _a.sent();
                        setLocaleMessage(locale, localMessages.value);
                        return [2 /*return*/];
                }
            });
        }); });
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            var tokenKey, storedToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokenKey = "key_".concat(props.mgmtCode);
                        storedToken = getTokenWithExpiry(tokenKey);
                        if (!storedToken) return [3 /*break*/, 2];
                        // 저장된 토큰으로 이력서 로드
                        return [4 /*yield*/, loadResume(props.mgmtCode, storedToken)];
                    case 1:
                        // 저장된 토큰으로 이력서 로드
                        _a.sent();
                        hasValidToken.value = checkToken();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, loadResume(props.mgmtCode)];
                    case 3:
                        _a.sent();
                        window.addEventListener('passwordSubmitted', function (event) {
                            var _a;
                            var password = ((_a = event.detail) === null || _a === void 0 ? void 0 : _a.password) || '';
                            if (password) {
                                authenticatePassword(password);
                                hasValidToken.value = checkToken();
                            }
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
        var UserResume = /** @class */ (function () {
            function UserResume() {
                this.code_metas = {
                    resume_task: [],
                    resume_location: [],
                    resume_con_visa: [],
                    resume_cv_topik: [],
                    resume_cv_livelocation: [],
                    resume_con_kor: [],
                    resume_con_edu: [],
                    resume_con_sex: [],
                    resume_info_country: [],
                };
                this.text_resume = {
                    name: "",
                    birthdate: "",
                    residence: "",
                    email: "",
                    mobile: "",
                    career: "",
                    major: "",
                    skills: "",
                    self_introduction: "",
                    con_visa: "",
                    con_sex: "",
                    info_country: "",
                    location: "",
                    task: "",
                };
                this.file_metas = {
                    resume_profile: [],
                    resume_attachments: []
                };
            }
            return UserResume;
        }());
        var metaPreset = /** @class */ (function () {
            function metaPreset() {
                this.task = [];
                this.location = [];
                this.con_visa = [];
                this.cv_topik = [];
                this.cv_livelocation = [];
                this.con_kor = [];
                this.con_edu = [];
                this.con_sex = [];
                this.info_country = [];
            }
            return metaPreset;
        }());
        var preset = ref(new metaPreset);
        var userResumeData = ref(new UserResume);
        var hasValidToken = ref(false);
        var isEmptyResume = ref(false);
        var isAuth = ref(false);
        var user = ref(null);
        var Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: function (toast) {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });
        var loadResume = function (mgmt_code, accessToken) {
            var _a = startSpinner(), spinnerContainer = _a.spinnerContainer, spinner = _a.spinner;
            // 헤더 설정
            var headers = accessToken
                ? { Authorization: "Bearer ".concat(accessToken) }
                : {};
            return axios
                .get("".concat(process.env.MIX_API_HOST, "/application/").concat(mgmt_code), { headers: headers })
                .then(function (_a) {
                var data = _a.data;
                return __awaiter(_this, void 0, void 0, function () {
                    var responseUserResume, type, uniqueCodes, field, value;
                    return __generator(this, function (_b) {
                        console.log(data);
                        responseUserResume = data.data;
                        isAuth = responseUserResume.is_auth || false;
                        // 초기화 후 데이터 채우기
                        userResumeData.value.code_metas = {
                            resume_task: [],
                            resume_location: [],
                            resume_con_visa: [],
                            resume_cv_topik: [],
                            resume_cv_livelocation: [],
                            resume_con_kor: [],
                            resume_con_edu: [],
                            resume_con_sex: [],
                            resume_info_country: [],
                        };
                        for (type in responseUserResume.code_metas) {
                            uniqueCodes = Array.from(new Set(responseUserResume.code_metas[type].map(function (item) { return item.code; })));
                            userResumeData.value.code_metas[type] = uniqueCodes;
                        }
                        // 파일 메타 초기화 후 데이터 채우기
                        userResumeData.value.file_metas.resume_profile = [];
                        userResumeData.value.file_metas.resume_attachments = [];
                        if (responseUserResume.file_metas.some(function (file) { return file.category === "resume_profile"; })) {
                            responseUserResume.file_metas
                                .filter(function (file) { return file.category === "resume_profile"; })
                                .forEach(function (_photo) {
                                var photo = new UserPhoto();
                                photo.id = _photo.id;
                                photo.filename = _photo.file_name;
                                photo.url = _photo.url;
                                photo.path = _photo.path;
                                photo.status = 'not_update';
                                userResumeData.value.file_metas.resume_profile.push(photo);
                            });
                        }
                        if (responseUserResume.file_metas.some(function (file) { return file.category === "resume_attachments"; })) {
                            responseUserResume.file_metas
                                .filter(function (file) { return file.category === "resume_attachments"; })
                                .forEach(function (_file) {
                                var file = new UserFile();
                                file.id = _file.id;
                                file.name = _file.file_name;
                                file.url = _file.url;
                                file.path = _file.path;
                                file.file_type = _file.type;
                                file.status = 'not_update';
                                userResumeData.value.file_metas.resume_attachments.push(file);
                            });
                        }
                        // 텍스트 메타 업데이트
                        if (responseUserResume.text_resume) {
                            isEmptyResume.value = false;
                            for (field in responseUserResume.text_resume.meta) {
                                value = responseUserResume.text_resume.meta[field];
                                userResumeData.value.text_resume[field] =
                                    field === 'birthdate' && value
                                        ? moment(value, 'YYYY-MM-DD').format('YYYY.MM.DD')
                                        : value;
                            }
                        }
                        return [2 /*return*/];
                    });
                });
            })
                .catch(function (_a) {
                var response = _a.response;
                if (response) {
                    Toast.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                    console.warn(response);
                }
            })
                .finally(function () {
                if (spinnerContainer) {
                    spinnerContainer.style.display = 'none';
                }
                spinner.stop();
            });
        };
        var loadPresetAndLocale = function () {
            return axios.get(process.env.MIX_API_HOST + '/service-code/preset', {
                params: {
                    parent_codes: [
                        "task",
                        "location",
                        "con_visa",
                        "cv_topik",
                        "cv_livelocation",
                        "con_kor",
                        "con_edu",
                        "con_sex",
                        "info_country"
                    ]
                }
            })
                .then(function (_a) {
                var data = _a.data;
                preset.value = new metaPreset;
                var _loop_1 = function (type) {
                    var list = data.data[type];
                    list.forEach(function (code) {
                        preset.value[type].push(code);
                        // i18n용 번역 세팅
                        localMessages.value["code.".concat(code.code)] = code["lang_".concat(locale)];
                    });
                };
                for (var type in data.data) {
                    _loop_1(type);
                }
            });
        };
        var saveTokenWithExpiry = function (key, token, ttl) {
            var now = new Date();
            var item = {
                token: token,
                expiry: now.getTime() + ttl,
            };
            localStorage.setItem(key, JSON.stringify(item));
        };
        var getTokenWithExpiry = function (key) {
            var itemStr = localStorage.getItem(key);
            // 키가 존재하지 않으면 null 반환
            if (!itemStr)
                return null;
            var item = JSON.parse(itemStr);
            var now = new Date();
            // 만료 시간 확인
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key);
                return null;
            }
            return item.token;
        };
        var authenticatePassword = function (password) {
            var _a = startSpinner(), spinnerContainer = _a.spinnerContainer, spinner = _a.spinner;
            // 비밀번호 인증 API 호출
            ApiService.post("".concat(process.env.MIX_API_HOST, "/application/").concat(props.mgmtCode, "/auth"), { password: password })
                .then(function (_a) {
                var data = _a.data;
                var tokenKey = "key_".concat(props.mgmtCode);
                var tokenTTL = data.data.expires_in * 1000;
                saveTokenWithExpiry(tokenKey, data.data.access_token, tokenTTL);
                window.location.reload();
            })
                .catch(function (_a) {
                var _b, _c;
                var response = _a.response;
                console.error('Authentication failed:', ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.message) || '비밀번호 인증 실패');
                Toast.fire({
                    text: ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.message) || '비밀번호 인증에 실패했습니다.',
                    icon: 'error',
                });
            })
                .finally(function () {
                if (spinnerContainer) {
                    spinnerContainer.style.display = 'none';
                }
                spinner.stop();
            });
        };
        var checkToken = function () {
            var tokenKey = "key_".concat(props.mgmtCode);
            var itemStr = localStorage.getItem(tokenKey);
            if (!itemStr)
                return false;
            var item = JSON.parse(itemStr);
            var now = new Date();
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(tokenKey);
                return false;
            }
            if (!isAuth)
                return false;
            return true;
        };
        var maskName = function (name) {
            if (!name)
                return '';
            return name.charAt(0) + '*'.repeat(name.length - 1);
        };
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "default", {
                    userResume: _unref(userResumeData),
                    preset: _unref(preset),
                    hasValidToken: _unref(hasValidToken),
                    maskName: maskName,
                    downloadHost: _unref(downloadHost)
                })
            ]));
        };
    }
});
