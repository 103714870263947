import './bootstrap';
import 'slick-carousel';

window.Swal = require('sweetalert2');
window.$ = window.jQuery = require('jquery');
window.bootstrap = require("bootstrap");
window.Popper = require("@popperjs/core");
window.lightbox = require('lightbox2');
require("select2");
require('jquery-serializejson');

window.Spinner = require('spin.js');
import {startSpinner} from "@/ts/modules/startSpinner.ts";
import { ServiceLanguages } from "@/ts/enums/ServiceLanguages";

import { createI18n } from 'vue-i18n'
let  initLocale = {};
for(let lang in Object.values(ServiceLanguages)) {
	initLocale[lang] = {};
}
const i18n = createI18n({
	locale: document.head.querySelector('meta[name="App-Language"]').content, // 현재 언어 세팅
	fallbackLocale: document.head.querySelector('meta[name="App-Language"]').content,
	messages: initLocale,
})
  

// import Vue from 'vue';
import { createApp } from 'vue'

import RecruitFilter from './components/RecruitFilter.vue';
import RecruitEtcFilter from './components/RecruitEtcFilter.vue';
import RecruitFilters from './components/RecruitFilters.vue';
import MobileRecruitFilter from './components/MobileRecruitFilter.vue';
import MobileRecruitEtcFilter from './components/MobileRecruitEtcFilter.vue';
import MobileRecruitFilters from './components/MobileRecruitFilters.vue';
import RecruitCreateVue from './components/RecruitCreate.vue';
import InterestFilters from './components/InterestFilters.vue';
import InterestFilter from './components/InterestFilter.vue';
import InterestEtcFilter from './components/InterestEtcFilter.vue';
import MobileInterestFilters from './components/MobileInterestFilters.vue';
import MobileInterestFilter from './components/MobileInterestFilter.vue';
import MobileInterestEtcFilter from './components/MobileInterestEtcFilter.vue';
import ResumeRadio from './components/ResumeRadio.vue';
import ResumeCheck from './components/ResumeCheck.vue';
import ResumeSelectBox from './components/ResumeSelectBox.vue';
import MobileResumeSelectBox from './components/MobileResumeSelectBox.vue';

import ResumePage from './components/ResumePage.vue';

const appContainer = document.getElementById('app');

// 기존 Vue 인스턴스가 있는 경우 언마운트
if (appContainer.__vue_app__) {
	appContainer.__vue_app__.unmount();
}

// 새로운 Vue 인스턴스 마운트
const app = createApp({});

app.component('recruit-filter', RecruitFilter);
app.component('recruit-etc-filter', RecruitEtcFilter);
app.component('recruit-filters', RecruitFilters);
app.component('m-recruit-filter', MobileRecruitFilter);
app.component('m-recruit-etc-filter', MobileRecruitEtcFilter);
app.component('m-recruit-filters', MobileRecruitFilters);
app.component('recruit-create-vue', RecruitCreateVue);
app.component('interest-filters', InterestFilters);
app.component('interest-filter', InterestFilter);
app.component('interest-etc-filter', InterestEtcFilter);
app.component('m-interest-filters', MobileInterestFilters);
app.component('m-interest-filter', MobileInterestFilter);
app.component('m-interest-etc-filter', MobileInterestEtcFilter);
app.component('resume-radio', ResumeRadio);
app.component('resume-check', ResumeCheck);
app.component('resume-page', ResumePage);
app.component('resume-select-box', ResumeSelectBox);
app.component('mobile-resume-select-box', MobileResumeSelectBox);

app.use(i18n);

import 'ckeditor5/ckeditor5.css';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
app.use(CkeditorPlugin);

app.mount('#app');


//각종 모달 뒷배경 스크롤 방지
	$('.back_wrap, .popup_background').on('scroll touchmove mousewheel', function(event) { // 터치무브와 마우스휠 스크롤 방지
		event.preventDefault();
		event.stopPropagation();
		return false;
	});

	//구직자등록버튼 언어감지
	$(document).ready(function(){
		let lang = document.documentElement.lang;
		let map = {
			ko : 'ibwryrP6zaNU3Em86',
			en : 'CF4NKum6EUb3TBT77',
			vi : 'CF4NKum6EUb3TBT77',
			mn : 'CF4NKum6EUb3TBT77',
			uz : 'CF4NKum6EUb3TBT77',
			};
		if(!map[lang]) {
			alert("언어가 감지되지 않습니다");
		}
		$('.enroll').attr('href', 'https://forms.gle/' + map[lang]);
	})

	//floating btn(모바일 모달 fade처리 포함)
	$('.floating_btn, .floating_btn_group .popup_background').click(function(){
		$('.floating_btn_group').toggleClass('show');
		$('.floating_btn_group .popup_background').fadeToggle(150, 'linear')
	})

	//상세페이지 모집조건, 근무조건 하위메뉴 하나일 때 너비 100%로 고정
	$(document).ready(function(){
		$('.recruit-field-table>.row').each(function(){
			let col = $(this).children('div')
			if(col.length < 2){
				col.addClass('col-12');
				col.removeClass('col-6');
			}
		});
	});

	//input 유효성 검사
	(() => {
		'use strict'
	
		const forms = document.querySelectorAll('.needs-validation')
	
		Array.from(forms).forEach(form => {
			form.addEventListener('submit', event => {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}
	
				form.classList.add('was-validated')
			}, false)
		})
	})()

	//password input eye icon
	$(function(){
		$('.eyes').on('click',function(){
			const thisInput = $(this).parents('.input-group').find('input')

			thisInput.toggleClass('active');
			if(thisInput.hasClass('active') == true ){
				$(this).find('.bi-eye-fill').attr('class',"bi bi-eye-slash-fill").parents('.input-group').find('input').attr('type',"text");
			} else{
				$(this).find('.bi-eye-slash-fill').attr('class',"bi bi-eye-fill").parents('.input-group').find('input').attr('type','password');
		}});
	});


	

	//지원하기 페이지 이메일로 문의 전송
	$('.apply_contents').submit(function(event){
		event.preventDefault();

		let lang = document.documentElement.lang;
		let data = $('.apply_contents').serializeJSON();

		const {spinnerContainer, spinner} = startSpinner();

		axios.post(process.env.MIX_API_HOST + '/mail/recruit/' + RECRUIT_ID + '/apply', data, {
			headers: data.getHeaders ? data.getHeaders() : { 'App-Language': lang }
		})
		.then((response) => {
			console.log(JSON.stringify(response.data));

			//로컬스토리지
			let form = $('.apply_contents');
			let rememberCheckbox = form.find('.saveinfo');

			if (!rememberCheckbox.is(':checked')) {
				form.find('input[type="text"], textarea, input[type="radio"], .saveinfo').each(function() {
					const name = $(this).attr('name');
					localStorage.removeItem(name);
				});
			} else {
				form.find('input[type="text"], textarea, input[type="radio"], .saveinfo').each(function() {
					const name = $(this).attr('name');
					if ($(this).attr('type') === 'checkbox') {
						localStorage.setItem(name, $(this).prop('checked').toString());
					} else if ($(this).attr('type') === 'radio') {
						if ($(this).is(':checked')) {
							localStorage.setItem(name, $(this).val());
						}
					} else {
						localStorage.setItem(name, $(this).val());
					}
				});
			}

			const singlebtnModal = $('#singlebtnModal');
			singlebtnModal.modal('show');

			$('.modal-footer button').click(function(){
				//모달의 확인 버튼 누르면 해당 채용공고 링크로 이동
				window.location.href = targetURL;
			});
		})
		.catch((error) => {
			console.log(error);

			let map = {
				name: {
					error_field: 'name',
					className: 'name_text'
				},
				contact: {
					error_field: 'contact',
					className: 'contact_text'
				},
				agree: {
					error_field: 'does_agree_privacy_policy',
					className: 'agree_input'
				},
			};
			let firstError = '';
			let errorClass = '';			
			for(let field in map) {
				let errorMessages = error.response.data.data.errors[map[field].error_field];
				if(errorMessages !== undefined){
					errorClass = map[field].className;
					firstError = errorMessages[0];
					break;
				}
			}	

			Swal.fire({
				position: 'center-center',
				icon: "error",
				title: error.response.data.message,
				buttonsStyling: false,
				confirmButtonText: confirmMessage,
				heightAuto: false,
				customClass: {
				confirmButton: "btn btn-primary",
				},
				didClose: () =>{
					$('.' + errorClass).focus();
				}
			})
		})
		.finally(() => {
			spinner.stop();
			spinnerContainer.style.display = 'none';
		});
		return false;
	})

	$(document).ready(function(){
		let form = $('.apply_contents');

		form.find('input[type="text"], textarea, input[type="radio"]').each(function() {
			const name = $(this).attr('name');
			const savedValue = localStorage.getItem(name);
			if (savedValue) {
				if ($(this).attr('type') === 'radio') {
					if ($(this).val() === savedValue) {
						$(this).prop('checked', true);
					}
				} else {
					$(this).val(savedValue);
				}
			}
		});

		let rememberCheckbox = form.find('.saveinfo');
		let savedRemember = localStorage.getItem(rememberCheckbox.attr('name'));

		if (savedRemember === 'true') {
			rememberCheckbox.prop('checked', true);
		}
	})

	//로그아웃
	$('.logout_btn').click(function(){
		let lang = document.documentElement.lang;

		axios.post('/logout', {})
		.then(({data}) => {
			console.log(data)
			localStorage.removeItem('email');
			localStorage.removeItem('token');
		})
		.catch(({response}) => {
			console.log(response.data.message);
		})	
		.finally(() => {
			window.location.href=goHome;
		})
	})

	//캐시 된 페이지 로드된 경우 강제 리로드하도록 처리
	window.addEventListener('pageshow', function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	});


	//scroll sticky
	window.onscroll = function() {
		if (typeof stickyTab !== "undefined" && typeof topHeight !== "undefined") {
			const windowTop = window.scrollY;
	
			if (windowTop >= topHeight) {
				stickyTab.classList.add("scroll_sticky");
				if (typeof container_top !== "undefined") {
					container_top.classList.add("top");
				}
			} else {
				stickyTab.classList.remove("scroll_sticky");
				if (typeof container_top !== "undefined") {
					container_top.classList.remove("top");
				}
			}
		}
	}