var ServiceCode = /** @class */ (function () {
    function ServiceCode() {
        this.code = '';
        this.parent_code = '';
        this.lang_ko = '';
        this.lang_en = '';
        this.lang_vi = '';
        this.sub_codes = '';
        this.active = false;
        this.children = [];
        this.post_count = 0;
        this.recruit_categories_count = 0;
        this.recruit_metas_count = 0;
        this.depth = 0;
    }
    return ServiceCode;
}());
export { ServiceCode };
