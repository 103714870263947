import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
    class: "modal fade",
    ref: "searchAddressModalRef",
    tabindex: "-1",
    "aria-hidden": "true"
};
var _hoisted_2 = { class: "modal-dialog" };
var _hoisted_3 = { class: "modal-content" };
var _hoisted_4 = {
    class: "modal-body address-body",
    ref: "modalBodyRef"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "modal-header border-0 justify-content-end" }, [
                    _createElementVNode("div", { "data-bs-dismiss": "modal" }, [
                        _createElementVNode("span", { class: "close-icon" }, [
                            _createElementVNode("i", { class: "bi bi-x" })
                        ])
                    ])
                ], -1)),
                _createElementVNode("div", _hoisted_4, null, 512)
            ])
        ])
    ], 512));
}
