import { toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
    slot: "selection",
    "slot-scope": "{ value, search, isOpen }"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_multiselect = _resolveComponent("multiselect");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_multiselect, {
            modelValue: _ctx.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.value) = $event); }),
            options: _ctx.options,
            multiple: true,
            "close-on-select": false,
            "clear-on-select": false,
            "preserve-search": true,
            placeholder: "옵션을 선택해 주세요",
            label: "lang_ko",
            "track-by": "lang_ko",
            "preselect-first": true,
            disabled: !_ctx.editable,
            onSelect: _ctx.onSelect,
            onInput: _ctx.onChange,
            onClose: _ctx.onClose,
            onRemove: _ctx.onRemove
        }, {
            default: _withCtx(function () { return [
                _createElementVNode("template", _hoisted_1, [
                    (_ctx.value.length)
                        ? _withDirectives((_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: "multiselect__single"
                        }, _toDisplayString(_ctx.value.length) + " options selected ", 513)), [
                            [_vShow, !_ctx.isOpen]
                        ])
                        : _createCommentVNode("", true)
                ])
            ]; }),
            _: 1
        }, 8, ["modelValue", "options", "disabled", "onSelect", "onInput", "onClose", "onRemove"])
    ]));
}
