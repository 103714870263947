import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow } from "vue";
var _hoisted_1 = { class: "position-relative" };
var _hoisted_2 = { class: "select_box" };
var _hoisted_3 = { class: "text-truncate d-flex flex-wrap fs-6.5" };
var _hoisted_4 = {
    key: 0,
    class: "hint"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = ["id"];
var _hoisted_7 = { class: "row filterUI-body" };
var _hoisted_8 = { class: "filterUI-body-right col h-100" };
var _hoisted_9 = { class: "row filterUI-body-list" };
var _hoisted_10 = { class: "form-check" };
var _hoisted_11 = ["id", "value"];
var _hoisted_12 = ["for"];
var _hoisted_13 = { class: "row filterUI-footer" };
var _hoisted_14 = { class: "result d-flex flex-wrap" };
var _hoisted_15 = ["name", "value"];
var _hoisted_16 = { class: "text-info mx-3" };
var _hoisted_17 = ["onClick"];
var _hoisted_18 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_19 = { class: "d-flex align-items-end" };
import { ref, watch } from 'vue';
// Props 정의
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResumeFilter',
    props: {
        options: {
            required: true,
            type: (Array),
        },
        initValue: {
            required: false,
            type: [String, Array],
            default: function () { return []; },
        },
        type: {
            required: true,
            type: String,
        },
        filterVisible: {
            required: true,
            type: Boolean,
        },
        locales: {
            required: true,
            type: Object,
        },
    },
    emits: ['filter-toggle-event', 'update:initValue'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        // Emits 정의
        var emit = __emit;
        // Reactive 데이터
        var lang = ref(document.documentElement.lang);
        var selectedCategory = ref([]);
        // Methods
        var syncSelectedCategory = function (newInitValue) {
            if (!props.options || props.options.length === 0)
                return;
            if (Array.isArray(newInitValue)) {
                selectedCategory.value = props.options.filter(function (option) {
                    return newInitValue.includes(option.code);
                });
            }
            else if (typeof newInitValue === 'string') {
                var selectedOption = props.options.find(function (option) { return option.code === newInitValue; });
                selectedCategory.value = selectedOption ? [selectedOption] : [];
            }
            else {
                selectedCategory.value = [];
            }
        };
        var updateInitValue = function (newSelectedCategory) {
            var selectedCodes = newSelectedCategory.map(function (category) { return category.code; });
            emit('update:initValue', selectedCodes);
        };
        var toggleFilterPopup = function () {
            emit('filter-toggle-event', props.type, !props.filterVisible);
        };
        var closeFilterPopup = function () {
            emit('filter-toggle-event', props.type, false);
        };
        var removeSelectedCategory = function (code) {
            selectedCategory.value = selectedCategory.value.filter(function (selected) { return selected.code !== code.code; });
        };
        var resetFilter = function () {
            selectedCategory.value = [];
        };
        // Watchers
        watch([function () { return props.initValue; }, function () { return props.options; }], function (_a) {
            var newInitValue = _a[0], newOptions = _a[1];
            if (newOptions && newOptions.length > 0) {
                // 약간의 딜레이 추가
                setTimeout(function () {
                    syncSelectedCategory(newInitValue);
                }, 100);
            }
        }, { immediate: true });
        watch(selectedCategory, function (newSelectedCategory) {
            updateInitValue(newSelectedCategory);
        }, { deep: true });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: "dropdown-toggle position-relative",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (toggleFilterPopup()); })
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            (selectedCategory.value.length === 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.type === 'task' ? __props.locales.common_jobhint : (__props.type === 'location' ? __props.locales.common_locationhint : '')), 1))
                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedCategory.value, function (code) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: 'title_' + code.code,
                                        class: "selected_code"
                                    }, [
                                        _createElementVNode("span", null, _toDisplayString(code['lang_' + lang.value]), 1),
                                        _createElementVNode("button", {
                                            class: "btn btn-sm",
                                            type: "button",
                                            "aria-label": "Close",
                                            onClick: _withModifiers(function ($event) { return (removeSelectedCategory(code)); }, ["stop", "prevent"])
                                        }, _cache[4] || (_cache[4] = [
                                            _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                        ]), 8, _hoisted_5)
                                    ]));
                                }), 128))
                        ]),
                        _cache[5] || (_cache[5] = _createElementVNode("button", { type: "button" }, [
                            _createElementVNode("i", { class: "bi bi-chevron-down" })
                        ], -1))
                    ])
                ]),
                _withDirectives(_createElementVNode("div", {
                    class: "filterUI",
                    id: __props.type + 'Filter'
                }, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, function (category) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        class: "filterUI-body-list-item",
                                        key: category.code
                                    }, [
                                        _createElementVNode("div", _hoisted_10, [
                                            _withDirectives(_createElementVNode("input", {
                                                class: "form-check-input",
                                                id: category.code,
                                                type: "checkbox",
                                                value: category,
                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((selectedCategory).value = $event); })
                                            }, null, 8, _hoisted_11), [
                                                [_vModelCheckbox, selectedCategory.value]
                                            ]),
                                            _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: category.code
                                            }, _toDisplayString(category['lang_' + lang.value]), 9, _hoisted_12)
                                        ])
                                    ]));
                                }), 128))
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("input", {
                                    key: 'hidden_' + code.code,
                                    type: "hidden",
                                    name: "".concat(__props.type, "[]"),
                                    value: code.code
                                }, null, 8, _hoisted_15));
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: 'selected_' + code.code,
                                    class: "d-flex align-items-center"
                                }, [
                                    (selectedCategory.value.length > 0)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("span", _hoisted_16, _toDisplayString(code['lang_' + lang.value]), 1),
                                            _createElementVNode("button", {
                                                class: "btn-close btn btn-sm",
                                                type: "button",
                                                "aria-label": "Close",
                                                onClick: _withModifiers(function ($event) { return (removeSelectedCategory(code)); }, ["stop", "prevent"])
                                            }, _cache[6] || (_cache[6] = [
                                                _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                            ]), 8, _hoisted_17)
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("button", {
                                    class: "btn btn-sm py-0",
                                    type: "button",
                                    onClick: _cache[2] || (_cache[2] = function ($event) { return (resetFilter()); })
                                }, [
                                    _createTextVNode(_toDisplayString(__props.locales.common_reset), 1),
                                    _cache[7] || (_cache[7] = _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1))
                                ])
                            ]),
                            _createElementVNode("button", {
                                onClick: _cache[3] || (_cache[3] = function ($event) { return (closeFilterPopup()); }),
                                class: "btn btn-primary text-white",
                                type: "button"
                            }, _toDisplayString(__props.locales.common_set), 1)
                        ])
                    ])
                ], 8, _hoisted_6), [
                    [_vShow, __props.filterVisible]
                ])
            ]));
        };
    }
});
