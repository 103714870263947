var UserPhoto = /** @class */ (function () {
    function UserPhoto() {
        this.id = 0;
        this.key = '';
        this.filename = '';
        this.url = '';
        this.path = '';
        this.status = 'create';
    }
    return UserPhoto;
}());
export { UserPhoto };
