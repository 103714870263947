var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = { class: "filter_popup_container" };
var _hoisted_2 = { class: "filter_input" };
var _hoisted_3 = ["name"];
var _hoisted_4 = ["id", "value", "placeholder"];
var _hoisted_5 = ["for"];
var _hoisted_6 = {
    key: 0,
    class: "filter_popup"
};
var _hoisted_7 = { class: "filter_popup_content" };
var _hoisted_8 = { class: "filter_popup_title" };
var _hoisted_9 = { class: "popup_search" };
var _hoisted_10 = ["onKeydown", "placeholder"];
var _hoisted_11 = { class: "popup_item_group" };
var _hoisted_12 = ["onClick"];
var _hoisted_13 = { class: "popup_actions" };
var _hoisted_14 = {
    key: 1,
    class: "invalid-feedback"
};
import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
var __default__ = {
    name: 'resume-select-box',
};
export default /*@__PURE__*/ _defineComponent(__assign(__assign({}, __default__), { props: {
        type: {
            required: true,
            type: String,
        },
        initValue: {
            required: false,
            type: String
        },
        options: {
            required: true,
            type: (Array)
        },
        hintText: {
            required: true,
            type: String
        },
        searchHint: {
            required: false,
            type: String
        },
        errText: {
            required: true,
            type: String
        },
        filterTitle: {
            required: true,
            type: String
        },
        btnText: {
            required: true,
            type: String
        }
    }, setup: function (__props) {
        var lang = document.documentElement.lang;
        var isOpen = ref(false);
        var isInvalid = ref(false);
        var props = __props;
        var selected = ref("");
        var selectedLabel = ref("");
        var searchQuery = ref("");
        // 임시 선택값을 위한 변수
        var tempSelected = ref("");
        var tempSelectedLabel = ref("");
        var previousSearchQuery = "";
        var onKeyup = function (event) {
            var currentQuery = event.target.value;
            // 검색어가 변경되었는지 확인
            if (currentQuery !== previousSearchQuery) {
                previousSearchQuery = currentQuery;
            }
            searchQuery.value = currentQuery;
        };
        // 키패드 닫기 함수
        var closeKeyboard = function (event) {
            event.preventDefault();
            var keyboardEvent = event; // 타입 캐스팅
            keyboardEvent.target.blur();
        };
        watch([function () { return props.options; }, function () { return props.initValue; }], function (_a) {
            var newOptions = _a[0], newInitValue = _a[1];
            if (newInitValue) {
                selected.value = newInitValue;
                var selectedOption = newOptions.find(function (option) { return option.code === selected.value; });
                if (selectedOption) {
                    selectedLabel.value = selectedOption["lang_".concat(lang)];
                }
                else {
                    selectedLabel.value = "";
                }
            }
        });
        // 브라우저 뒤로 가기 이벤트 리스너 등록
        onMounted(function () {
            window.addEventListener('popstate', handlePopState);
        });
        // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
        onBeforeUnmount(function () {
            window.removeEventListener('popstate', handlePopState);
        });
        // 팝업 열기/닫기
        var togglePopup = function () {
            isOpen.value = !isOpen.value;
            if (isOpen.value) {
                history.pushState({ popupOpen: true }, 'filter', '#filter');
                tempSelected.value = selected.value;
                tempSelectedLabel.value = selectedLabel.value;
                searchQuery.value = '';
            }
            else {
                if (window.location.hash === '#filter') {
                    history.back();
                }
            }
            toggleBodyScroll(isOpen.value);
        };
        // 팝업 닫기
        var closePopup = function () {
            tempSelected.value = selected.value;
            tempSelectedLabel.value = selectedLabel.value;
            isOpen.value = false;
            toggleBodyScroll(false);
            if (window.location.hash === '#filter') {
                history.back();
            }
        };
        var handlePopState = function () {
            if (isOpen.value) {
                closePopup(); // 팝업이 열려 있을 경우 닫기
            }
        };
        // 선택된 값을 적용
        var applySelection = function () {
            selected.value = tempSelected.value;
            selectedLabel.value = tempSelectedLabel.value;
            closePopup();
        };
        // 옵션 선택 시
        var selectOption = function (option) {
            tempSelected.value = option.code;
            tempSelectedLabel.value = option["lang_".concat(lang)];
        };
        // 유효성 검사
        var validateSelection = function () {
            isInvalid.value = !selected.value;
        };
        var filteredOptions = computed(function () {
            var lowerCaseQuery = searchQuery.value.toLowerCase();
            var isInitialSearch = isKoreanInitial(lowerCaseQuery); // 입력된 문자열이 초성인지 여부
            return props.options.filter(function (option) {
                var optionLabel = option["lang_".concat(lang)].toLowerCase();
                var optionInitials = extractInitials(option["lang_".concat(lang)].toLowerCase());
                // 초성 검색일 경우 초성으로 필터, 아니면 전체 텍스트로 필터
                if (isInitialSearch) {
                    return optionInitials.startsWith(lowerCaseQuery); // 초성 매칭
                }
                else {
                    return optionLabel.includes(lowerCaseQuery); // 전체 문자 매칭
                }
            });
        });
        // 한글 초성 추출 함수
        var extractInitials = function (str) {
            var initialConsonants = [
                'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
            ];
            return str.split('').map(function (char) {
                var code = char.charCodeAt(0) - 44032;
                // 한글이 아닌 경우 그대로 반환
                if (code < 0 || code > 11171) {
                    return char;
                }
                return initialConsonants[Math.floor(code / 588)];
            }).join('');
        };
        var isKoreanInitial = function (str) {
            return /^[ㄱ-ㅎ]+$/.test(str); // 초성만 있는지 확인
        };
        // 스크롤 제어 함수
        var toggleBodyScroll = function (disableScroll) {
            if (disableScroll) {
                document.body.style.overflow = 'hidden';
            }
            else {
                document.body.style.overflow = '';
            }
        };
        window.validateSelection = validateSelection;
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (isOpen.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "filter-backdrop",
                        onClick: closePopup
                    }))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            name: "".concat(props.type),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (_isRef(selected) ? (selected).value = $event : selected = $event); }),
                            required: ""
                        }, null, 8, _hoisted_3), [
                            [_vModelText, _unref(selected)]
                        ]),
                        _createElementVNode("input", {
                            id: "".concat(props.type),
                            class: "dropdown_toggle",
                            onClick: togglePopup,
                            value: _unref(selectedLabel),
                            placeholder: __props.hintText,
                            readonly: ""
                        }, null, 8, _hoisted_4),
                        _createElementVNode("label", {
                            for: "".concat(props.type)
                        }, _cache[2] || (_cache[2] = [
                            _createElementVNode("i", { class: "bi bi-chevron-down" }, null, -1)
                        ]), 8, _hoisted_5)
                    ]),
                    (isOpen.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("h6", null, _toDisplayString(__props.filterTitle), 1),
                                    _createElementVNode("i", {
                                        class: "bi bi-x",
                                        onClick: closePopup
                                    })
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("input", {
                                        type: "search",
                                        onKeyup: onKeyup,
                                        onKeydown: _withKeys(_withModifiers(closeKeyboard, ["prevent"]), ["enter"]),
                                        placeholder: __props.searchHint
                                    }, null, 40, _hoisted_10),
                                    _withDirectives(_createElementVNode("input", {
                                        type: "hidden",
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((searchQuery).value = $event); })
                                    }, null, 512), [
                                        [_vModelText, searchQuery.value]
                                    ]),
                                    _cache[3] || (_cache[3] = _createElementVNode("i", { class: "bi bi-search" }, null, -1))
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, function (code) {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: code.code,
                                            class: _normalizeClass([{ 'selected_item': code.code === _unref(tempSelected) }, "popup_item"]),
                                            onClick: function ($event) { return (selectOption(code)); }
                                        }, [
                                            _createElementVNode("span", null, _toDisplayString(code["lang_".concat(_unref(lang))]), 1),
                                            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-check-lg" }, null, -1))
                                        ], 10, _hoisted_12));
                                    }), 128))
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("button", {
                                        onClick: applySelection,
                                        type: "button"
                                    }, _toDisplayString(__props.btnText), 1)
                                ])
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (isInvalid.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, "＊ " + _toDisplayString(__props.errText), 1))
                        : _createCommentVNode("", true)
                ])
            ], 64));
        };
    } }));
