var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow } from "vue";
var _hoisted_1 = { class: "select_box" };
var _hoisted_2 = { class: "text-truncate d-flex flex-wrap fs-6.5" };
var _hoisted_3 = {
    key: 0,
    class: "hint",
    style: { "font-size": "0.9rem" }
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
    key: 0,
    class: "popup_background"
};
var _hoisted_6 = ["id"];
var _hoisted_7 = { class: "filterUI-title" };
var _hoisted_8 = { class: "title-line d-flex justify-content-between align-items-center" };
var _hoisted_9 = { class: "filter_search" };
var _hoisted_10 = ["onKeydown"];
var _hoisted_11 = { class: "row filterUI-body" };
var _hoisted_12 = { class: "filterUI-body-right col h-100" };
var _hoisted_13 = { class: "row filterUI-body-list" };
var _hoisted_14 = { class: "form-check" };
var _hoisted_15 = ["id", "value"];
var _hoisted_16 = ["for"];
var _hoisted_17 = { class: "row filterUI-footer" };
var _hoisted_18 = { class: "select_title" };
var _hoisted_19 = { class: "result d-flex flex-wrap" };
var _hoisted_20 = ["name", "value"];
var _hoisted_21 = { class: "text-info mx-3" };
var _hoisted_22 = ["onClick"];
var _hoisted_23 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_24 = { class: "d-flex flex-sm-row align-items-center select-btns" };
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
// Props
export default /*@__PURE__*/ _defineComponent({
    __name: 'MobileCodeMetaFilter',
    props: {
        type: {
            required: true,
            type: String,
        },
        options: {
            required: true,
            type: (Array),
            default: [],
        },
        activeFilter: {
            type: String,
            default: null
        },
    },
    emits: ['update-checked-code', 'update-active-filter'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        // Reactive 데이터
        var lang = ref(document.documentElement.lang);
        var filterVisible = ref(false);
        var selectedCategory = ref([]);
        var pendingCategory = ref([]); // 임시 저장소 역할
        var searchQuery = ref(''); // 검색어
        var filterContainer = ref(null);
        var emit = __emit;
        var previousSearchQuery = "";
        watch(function () { return props.activeFilter; }, function (newVal) {
            filterVisible.value = newVal === props.type;
        });
        // Methods
        var onKeyup = function (event) {
            var currentQuery = event.target.value;
            // 검색어가 변경되었는지 확인
            if (currentQuery !== previousSearchQuery) {
                previousSearchQuery = currentQuery;
            }
            searchQuery.value = currentQuery;
        };
        // 키패드 닫기 함수
        var closeKeyboard = function (event) {
            event.preventDefault();
            var keyboardEvent = event; // 타입 캐스팅
            keyboardEvent.target.blur();
        };
        var toggleFilterPopup = function () {
            emit('update-active-filter', props.type);
        };
        var resetFilter = function () {
            selectedCategory.value = [];
            pendingCategory.value = [];
            filterVisible;
        };
        var applyPendingCategory = function () {
            selectedCategory.value = __spreadArray([], pendingCategory.value, true); // pendingCategory 내용을 확정
            filterVisible.value = false;
            emit('update-checked-code', selectedCategory.value);
        };
        var initPendingCategory = function () {
            // pendingCategory를 selectedCategory로 초기화
            pendingCategory.value = __spreadArray([], selectedCategory.value, true);
        };
        var currentLang = computed(function () { return lang.value; });
        var filteredOptions = computed(function () {
            var _a;
            var lowerCaseQuery = ((_a = searchQuery.value) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) || '';
            var isInitialSearch = isKoreanInitial(lowerCaseQuery);
            return props.options.filter(function (option) {
                var _a;
                var optionLabel = ((_a = option["lang_".concat(currentLang.value)]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
                var optionInitials = extractInitials(optionLabel);
                if (isInitialSearch) {
                    return optionInitials.startsWith(lowerCaseQuery);
                }
                else {
                    return optionLabel.includes(lowerCaseQuery);
                }
            });
        });
        var removeFromPendingCategory = function (code) {
            pendingCategory.value = pendingCategory.value.filter(function (selected) { return selected.code !== code.code; });
        };
        var removeSelectedCategory = function (code) {
            selectedCategory.value = selectedCategory.value.filter(function (selected) { return selected.code !== code.code; });
        };
        // 외부 클릭 감지 로직
        var handleOutsideClick = function (event) {
            if (filterContainer.value && !filterContainer.value.contains(event.target)) {
                filterVisible.value = false;
            }
        };
        // 마운트 및 언마운트 시 이벤트 등록 및 제거
        onMounted(function () {
            document.addEventListener('click', handleOutsideClick);
        });
        onBeforeUnmount(function () {
            document.removeEventListener('click', handleOutsideClick);
        });
        // 한글 초성 추출 함수
        var extractInitials = function (str) {
            var initialConsonants = [
                'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
            ];
            return str.split('').map(function (char) {
                var code = char.charCodeAt(0) - 44032;
                // 한글이 아닌 경우 그대로 반환
                if (code < 0 || code > 11171) {
                    return char;
                }
                return initialConsonants[Math.floor(code / 588)];
            }).join('');
        };
        var isKoreanInitial = function (str) {
            return /^[ㄱ-ㅎ]+$/.test(str); // 초성만 있는지 확인
        };
        // Watchers
        watch(selectedCategory, function (newSelectedCategory) {
            emit('update-checked-code', props.type, newSelectedCategory);
        }, { deep: true });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", {
                class: "position-relative",
                ref_key: "filterContainer",
                ref: filterContainer
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: "dropdown-toggle position-relative",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (toggleFilterPopup()); })
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            (selectedCategory.value.length === 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.type === 'info_country' ? '우대국가를 선택해주세요' :
                                    props.type === 'con_language' ? '언어능력을 선택해주세요' : ''), 1))
                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedCategory.value, function (code) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: 'title_' + code.code,
                                        class: "selected_code"
                                    }, [
                                        _createElementVNode("span", null, _toDisplayString(code['lang_' + lang.value]), 1),
                                        _createElementVNode("button", {
                                            class: "btn btn-sm",
                                            type: "button",
                                            "aria-label": "Close",
                                            onClick: _withModifiers(function ($event) { return (removeSelectedCategory(code)); }, ["stop"])
                                        }, _cache[7] || (_cache[7] = [
                                            _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                        ]), 8, _hoisted_4)
                                    ]));
                                }), 128))
                        ]),
                        _cache[8] || (_cache[8] = _createElementVNode("button", { type: "button" }, [
                            _createElementVNode("i", { class: "bi bi-chevron-down" })
                        ], -1))
                    ])
                ]),
                _createElementVNode("div", {
                    class: "back_wrap",
                    onClick: _cache[1] || (_cache[1] = function ($event) { return ([toggleFilterPopup(), initPendingCategory()]); })
                }, [
                    _createVNode(_Transition, null, {
                        default: _withCtx(function () { return [
                            (filterVisible.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                                : _createCommentVNode("", true)
                        ]; }),
                        _: 1
                    })
                ]),
                _withDirectives(_createElementVNode("div", {
                    class: "filterUI",
                    id: __props.type + 'Filter'
                }, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("span", null, _toDisplayString(props.type === 'info_country' ? '우대국가' :
                                props.type === 'con_language' ? '언어능력' : ''), 1),
                            _createElementVNode("span", {
                                class: "close_btn",
                                onClick: _cache[2] || (_cache[2] = function ($event) { return ([toggleFilterPopup(), initPendingCategory()]); })
                            }, _cache[9] || (_cache[9] = [
                                _createElementVNode("i", { class: "bi bi-x" }, null, -1)
                            ]))
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("input", {
                            type: "search",
                            onKeyup: onKeyup,
                            onKeydown: _withKeys(_withModifiers(closeKeyboard, ["prevent"]), ["enter"]),
                            placeholder: "검색해주세요."
                        }, null, 40, _hoisted_10),
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((searchQuery).value = $event); })
                        }, null, 512), [
                            [_vModelText, searchQuery.value]
                        ]),
                        _cache[10] || (_cache[10] = _createElementVNode("i", { class: "bi bi-search" }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, function (category) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        class: "filterUI-body-list-item",
                                        key: category.code
                                    }, [
                                        _createElementVNode("div", _hoisted_14, [
                                            _withDirectives(_createElementVNode("input", {
                                                class: "form-check-input",
                                                id: category.code,
                                                type: "checkbox",
                                                value: category,
                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((pendingCategory).value = $event); })
                                            }, null, 8, _hoisted_15), [
                                                [_vModelCheckbox, pendingCategory.value]
                                            ]),
                                            _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: category.code
                                            }, _toDisplayString(category['lang_' + lang.value]), 9, _hoisted_16)
                                        ])
                                    ]));
                                }), 128))
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                            _createTextVNode(_toDisplayString(props.type === 'info_country' ? '선택된 우대국가' :
                                props.type === 'con_language' ? '선택된 언어능력' : '') + " ", 1),
                            _createElementVNode("span", null, _toDisplayString(pendingCategory.value.length), 1)
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("input", {
                                    key: 'hidden_' + code.code,
                                    type: "hidden",
                                    name: "".concat(__props.type, "[]"),
                                    value: code.code
                                }, null, 8, _hoisted_20));
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pendingCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: 'selected_' + code.code,
                                    class: "d-flex align-items-center"
                                }, [
                                    (pendingCategory.value.length > 0)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("span", _hoisted_21, _toDisplayString(code['lang_' + lang.value]), 1),
                                            _createElementVNode("button", {
                                                class: "btn-close btn btn-sm",
                                                type: "button",
                                                "aria-label": "Close",
                                                onClick: _withModifiers(function ($event) { return (removeFromPendingCategory(code)); }, ["stop"])
                                            }, _cache[11] || (_cache[11] = [
                                                _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                            ]), 8, _hoisted_22)
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("button", {
                                    class: "btn btn-sm py-0",
                                    type: "button",
                                    onClick: _cache[5] || (_cache[5] = function ($event) { return (resetFilter()); })
                                }, _cache[12] || (_cache[12] = [
                                    _createTextVNode(" 초기화"),
                                    _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1)
                                ])),
                                _createElementVNode("button", {
                                    onClick: _cache[6] || (_cache[6] = function ($event) { return (applyPendingCategory()); }),
                                    class: "btn btn-primary text-white",
                                    type: "button"
                                }, "적용")
                            ])
                        ])
                    ])
                ], 8, _hoisted_6), [
                    [_vShow, filterVisible.value]
                ])
            ], 512));
        };
    }
});
