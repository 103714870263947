import { ServiceCode } from "@/ts/ServiceCode";
var RecruitCodeMeta = /** @class */ (function () {
    function RecruitCodeMeta(code) {
        this.id = 0;
        this.recruit_id = 0;
        this.type = '';
        this.code = '';
        this.active = true;
        this.code_data = new ServiceCode();
        this.code_data = code;
        this.code = code.code;
    }
    return RecruitCodeMeta;
}());
export { RecruitCodeMeta };
