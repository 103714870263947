var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
    key: 0,
    class: "d-flex align-items-center"
};
var _hoisted_2 = ["name", "id"];
var _hoisted_3 = ["for"];
var _hoisted_4 = ["name", "value", "id"];
var _hoisted_5 = ["for"];
import { ref, watch } from 'vue';
var __default__ = {
    name: 'resume-radio',
};
export default /*@__PURE__*/ _defineComponent(__assign(__assign({}, __default__), { props: {
        type: {
            required: true,
            type: String,
        },
        initValue: {
            required: false,
            type: String,
        },
        options: {
            required: true,
            type: (Array)
        },
        /**
         * 빈 값인 선택 옵션 표시 여부
         */
        displayEmptyOption: {
            required: false,
            type: Boolean,
            default: true
        },
        /**
         * 빈 값인 선택 옵션의 텍스트
         */
        emptyOptionText: {
            required: false,
            type: String,
            default: "없음"
        }
    }, setup: function (__props) {
        var lang = document.documentElement.lang;
        /**
         * 해당 코드는 표시하지 않는다.
         */
        var codesToIgnore = [
            "cv_topik_07",
            "con_edu_07", // 학력 - 학력무관
        ];
        var props = __props;
        var selected = ref("");
        watch(function () { return props.initValue; }, function (newVal) {
            selected.value = newVal;
        });
        // options 배열이 변경될 때도 첫 번째 옵션 선택
        watch(function () { return props.options; }, function (newVal, oldVal) {
            if (props.type === 'con_kor')
                return;
            if (props.options.length > 0 && props.displayEmptyOption == false) {
                var firstOption = props.options[0];
                if (!codesToIgnore.includes(firstOption.code)) {
                    selected.value = firstOption.code;
                }
            }
        });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (__props.displayEmptyOption)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _withDirectives(_createElementVNode("input", {
                            type: "radio",
                            name: "".concat(props.type),
                            value: "",
                            id: "".concat(props.type, "_empty"),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (_isRef(selected) ? (selected).value = $event : selected = $event); })
                        }, null, 8, _hoisted_2), [
                            [_vModelRadio, _unref(selected)]
                        ]),
                        _createElementVNode("label", {
                            for: "".concat(props.type, "_empty")
                        }, _toDisplayString(__props.emptyOptionText), 9, _hoisted_3)
                    ]))
                    : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, function (code) {
                    return (_openBlock(), _createElementBlock("div", {
                        key: code.code,
                        class: "d-flex align-items-center"
                    }, [
                        (_unref(codesToIgnore).includes(code.code) == false)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "radio",
                                    name: "".concat(props.type),
                                    value: code.code,
                                    id: code.code,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return (_isRef(selected) ? (selected).value = $event : selected = $event); })
                                }, null, 8, _hoisted_4), [
                                    [_vModelRadio, _unref(selected)]
                                ]),
                                _createElementVNode("label", {
                                    for: code.code
                                }, _toDisplayString(code["lang_".concat(_unref(lang))]), 9, _hoisted_5)
                            ], 64))
                            : _createCommentVNode("", true)
                    ]));
                }), 128))
            ], 64));
        };
    } }));
