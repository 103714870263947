import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "interest_filter d-flex flex-column w-100" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_interest_filter = _resolveComponent("interest-filter");
    var _component_interest_etc_filter = _resolveComponent("interest-etc-filter");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createElementVNode("div", {
            class: "filter-backdrop",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return (_ctx.closeFilter && _ctx.closeFilter.apply(_ctx, args));
                })
        }, null, 512), [
            [_vShow, _ctx.showBackdrop]
        ]),
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_interest_filter, {
                ref: "taskRef",
                type: "task",
                title: _ctx.titles.task,
                locales: _ctx.locales,
                "request-codes": _ctx.userInterests.interest_task,
                "request-categories": _ctx.userInterests.interest_task_category,
                onFilterToggleEvent: _ctx.toggleUnusedUI,
                onFilterCloseEvent: _ctx.closeFilter
            }, null, 8, ["title", "locales", "request-codes", "request-categories", "onFilterToggleEvent", "onFilterCloseEvent"]),
            _createVNode(_component_interest_filter, {
                ref: "locationRef",
                type: "location",
                title: _ctx.titles.location,
                locales: _ctx.locales,
                "request-codes": _ctx.userInterests.interest_location,
                "request-categories": _ctx.userInterests.interest_location_category,
                onFilterToggleEvent: _ctx.toggleUnusedUI,
                onFilterCloseEvent: _ctx.closeFilter
            }, null, 8, ["title", "locales", "request-codes", "request-categories", "onFilterToggleEvent", "onFilterCloseEvent"]),
            _createVNode(_component_interest_etc_filter, {
                ref: "etcRef",
                type: "etc",
                title: _ctx.titles.visa,
                locales: _ctx.locales,
                "request-codes": _ctx.userInterests.interest_con_visa,
                "request-categories": _ctx.userInterests.interest_con_visa_category,
                onFilterToggleEvent: _ctx.toggleUnusedUI,
                onFilterCloseEvent: _ctx.closeFilter
            }, null, 8, ["title", "locales", "request-codes", "request-categories", "onFilterToggleEvent", "onFilterCloseEvent"])
        ])
    ], 64));
}
