var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import ApiService from '@/ts/core/services/ApiService';
import { startSpinner } from '@/ts/modules/startSpinner';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ref, onMounted, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserPhoto } from '../../ts/UserPhoto';
import moment from 'moment';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResumePage',
    setup: function (__props) {
        var _this = this;
        var langHeader = document.head.querySelector('meta[name="App-Language"]');
        var locale = langHeader.content;
        var localMessages = ref({});
        var setLocaleMessage = useI18n().setLocaleMessage;
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadPresetAndLocale()];
                    case 1:
                        _a.sent();
                        setLocaleMessage(locale, localMessages.value);
                        return [2 /*return*/];
                }
            });
        }); });
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadResume()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        var UserResume = /** @class */ (function () {
            function UserResume() {
                this.code_metas = {
                    resume_task: [],
                    resume_location: [],
                    resume_con_visa: [],
                    resume_cv_topik: [],
                    resume_cv_livelocation: [],
                    resume_con_kor: [],
                    resume_con_edu: [],
                    resume_con_sex: [],
                    resume_info_country: [],
                };
                this.text_resume = {
                    name: "",
                    birthdate: "",
                    residence: "",
                    email: "",
                    mobile: "",
                    career: "",
                    major: "",
                    skills: "",
                    self_introduction: "",
                };
                this.file_metas = {
                    resume_profile: []
                };
            }
            return UserResume;
        }());
        var metaPreset = /** @class */ (function () {
            function metaPreset() {
                this.task = [];
                this.location = [];
                this.con_visa = [];
                this.cv_topik = [];
                this.cv_livelocation = [];
                this.con_kor = [];
                this.con_edu = [];
                this.con_sex = [];
                this.info_country = [];
            }
            return metaPreset;
        }());
        var preset = ref(new metaPreset);
        var userResumeData = ref(new UserResume);
        var isEmptyResume = ref(false);
        var user = ref(null);
        var Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: function (toast) {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });
        /**
         * text_resume 가 없을 경우를 위한 회원정보 로드
         */
        var loadUser = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, spinnerContainer, spinner;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = startSpinner(), spinnerContainer = _a.spinnerContainer, spinner = _a.spinner;
                        return [4 /*yield*/, ApiService.post("".concat(process.env.MIX_API_HOST, "/auth/verify"), {})
                                .then(function (_a) {
                                var data = _a.data;
                                console.log(data.data);
                                user.value = data.data.user;
                            })
                                .catch(function (_a) {
                                var response = _a.response;
                                if (response) {
                                    console.warn(response);
                                }
                            })
                                .finally(function () {
                                if (spinnerContainer) {
                                    spinnerContainer.style.display = 'none';
                                }
                                spinner.stop();
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        var loadResume = function () {
            var _a = startSpinner(), spinnerContainer = _a.spinnerContainer, spinner = _a.spinner;
            return ApiService.get("".concat(process.env.MIX_API_HOST, "/my/resume"))
                .then(function (_a) {
                var data = _a.data;
                return __awaiter(_this, void 0, void 0, function () {
                    var responseUserResume, _loop_1, type, field;
                    var _b;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                console.log(data);
                                responseUserResume = data.data;
                                _loop_1 = function (type) {
                                    responseUserResume.code_metas[type].forEach(function (item) {
                                        userResumeData.value.code_metas[type].push(item.code);
                                    });
                                };
                                for (type in responseUserResume.code_metas) {
                                    _loop_1(type);
                                }
                                if (((_b = responseUserResume.file_metas) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                                    responseUserResume.file_metas.forEach(function (_photo) {
                                        var photo = new UserPhoto;
                                        photo.id = _photo.id;
                                        photo.filename = _photo.file_name;
                                        photo.url = _photo.url;
                                        photo.path = _photo.path;
                                        photo.status = 'not_update';
                                        userResumeData.value.file_metas.resume_profile.push(photo);
                                    });
                                }
                                if (!responseUserResume.text_resume) return [3 /*break*/, 1];
                                isEmptyResume.value = false;
                                for (field in responseUserResume.text_resume.meta) {
                                    if (field === 'birthdate' && responseUserResume.text_resume.meta[field]) {
                                        userResumeData.value.text_resume[field] = moment(responseUserResume.text_resume.meta[field], 'YYYY-MM-DD').format('YYYY.MM.DD');
                                    }
                                    else {
                                        userResumeData.value.text_resume[field] = responseUserResume.text_resume.meta[field];
                                    }
                                }
                                return [3 /*break*/, 3];
                            case 1: return [4 /*yield*/, loadUser()];
                            case 2:
                                _c.sent();
                                if (user.value != null) {
                                    userResumeData.value.text_resume.name = user.value.name;
                                    userResumeData.value.text_resume.email = user.value.email;
                                    userResumeData.value.text_resume.mobile = user.value.mobile;
                                }
                                _c.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            })
                .catch(function (_a) {
                var response = _a.response;
                if (response) {
                    Toast.fire({
                        text: response.data.message,
                        icon: "error",
                    });
                    console.warn(response);
                }
            })
                .finally(function () {
                if (spinnerContainer) {
                    spinnerContainer.style.display = 'none';
                }
                spinner.stop();
            });
        };
        var loadPresetAndLocale = function () {
            return axios.get(process.env.MIX_API_HOST + '/service-code/preset', {
                params: {
                    parent_codes: [
                        "task",
                        "location",
                        "con_visa",
                        "cv_topik",
                        "cv_livelocation",
                        "con_kor",
                        "con_edu",
                        "con_sex",
                        "info_country"
                    ]
                }
            })
                .then(function (_a) {
                var data = _a.data;
                preset.value = new metaPreset;
                var _loop_2 = function (type) {
                    var list = data.data[type];
                    list.forEach(function (code) {
                        preset.value[type].push(code);
                        // i18n용 번역 세팅
                        localMessages.value["code.".concat(code.code)] = code["lang_".concat(locale)];
                    });
                };
                for (var type in data.data) {
                    _loop_2(type);
                }
            });
        };
        // 이미지 미리보기 URL
        var previewImageUrl = ref(null);
        // 이미지 파일 선택 시 API로 전송
        var uploadImage = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var input, selectedFile, reader, formData;
            return __generator(this, function (_a) {
                input = event.target;
                if (!input.files || input.files.length === 0) {
                    return [2 /*return*/];
                }
                selectedFile = null;
                // 첫 번째 파일 선택
                selectedFile = input.files[0];
                reader = new FileReader();
                reader.onload = function (e) {
                    var _a;
                    previewImageUrl.value = (_a = e.target) === null || _a === void 0 ? void 0 : _a.result;
                    // 미리보기 화면에 이미지 표시
                    var previewView = document.getElementById('imagePreview');
                    var previewDiv = document.getElementById('previewDiv');
                    if (previewView && previewDiv) {
                        previewView.style.backgroundImage = "url(".concat(previewImageUrl.value, ")");
                        previewDiv.style.display = 'block';
                    }
                };
                reader.readAsDataURL(selectedFile); // 파일을 읽어 데이터 URL로 변환
                formData = new FormData();
                formData.append('images[]', selectedFile);
                axios.post("".concat(process.env.MIX_API_HOST, "/upload/image"), formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then(function (response) {
                    console.info(response.data);
                    userResumeData.value.file_metas.resume_profile = userResumeData.value.file_metas.resume_profile.filter(function (item) { return item.id != 0; });
                    userResumeData.value.file_metas.resume_profile.forEach(function (item) {
                        item.status = 'delete';
                    });
                    response.data.data.forEach(function (element) {
                        var photo = new UserPhoto;
                        photo.id = 0;
                        photo.filename = element.name;
                        photo.url = element.url;
                        photo.path = element.path;
                        photo.status = 'create';
                        userResumeData.value.file_metas.resume_profile.push(photo);
                    });
                })
                    .catch(function (err) {
                    console.error(err, err.response.data);
                    var errMessage = err.response.data ? err.response.data.message : err.response.statusText;
                    Swal.fire({
                        text: errMessage,
                        icon: "error",
                        confirmButtonText: "확인",
                        heightAuto: false,
                    });
                });
                return [2 /*return*/];
            });
        }); };
        // 이미지 삭제 기능
        var removeImage = function () {
            userResumeData.value.file_metas.resume_profile = userResumeData.value.file_metas.resume_profile.filter(function (item) { return item.id != 0; });
            userResumeData.value.file_metas.resume_profile.forEach(function (item) {
                item.status = 'delete';
            });
            previewImageUrl.value = null;
            var input = document.getElementById('photo');
            if (input)
                input.value = ''; // input 초기화
            var previewView = document.getElementById('imagePreview');
            var previewDiv = document.getElementById('previewDiv');
            if (previewView && previewDiv) {
                previewView.style.backgroundImage = 'none';
                previewDiv.style.display = 'none';
            }
        };
        var activeElement = ref(null);
        // 토글 함수: 하나가 열리면 다른 것들은 닫히도록
        var handleToggle = function (elementType) {
            activeElement.value = activeElement.value === elementType ? null : elementType;
        };
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "default", {
                    userResume: _unref(userResumeData),
                    preset: _unref(preset),
                    changeProfileImage: uploadImage,
                    removeProfileImage: removeImage,
                    handleToggle: handleToggle,
                    activeElement: activeElement.value
                })
            ]));
        };
    }
});
