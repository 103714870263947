import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vShow as _vShow, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = { class: "position-relative" };
var _hoisted_2 = { class: "filter_title_group" };
var _hoisted_3 = {
    class: "title",
    id: "basic-addon1"
};
var _hoisted_4 = { class: "desc" };
var _hoisted_5 = { class: "select_box" };
var _hoisted_6 = { class: "text-truncate d-flex flex-wrap fs-6.5" };
var _hoisted_7 = {
    key: 0,
    class: "hint"
};
var _hoisted_8 = { key: 0 };
var _hoisted_9 = { key: 1 };
var _hoisted_10 = ["onClick"];
var _hoisted_11 = {
    key: 0,
    class: "popup_background"
};
var _hoisted_12 = ["id"];
var _hoisted_13 = { class: "filterUI-title" };
var _hoisted_14 = { class: "title-line d-flex justify-content-between align-items-center" };
var _hoisted_15 = { class: "row filterUI-body" };
var _hoisted_16 = { class: "col-4 border-end h-100" };
var _hoisted_17 = { class: "filterUI-body-left list-group list-group-flush filterUI-body-category" };
var _hoisted_18 = ["onClick"];
var _hoisted_19 = { class: "filterUI-body-right col h-100" };
var _hoisted_20 = { class: "row filterUI-body-list" };
var _hoisted_21 = { class: "filterUI-body-list-item" };
var _hoisted_22 = { class: "form-check" };
var _hoisted_23 = ["id", "value"];
var _hoisted_24 = ["for"];
var _hoisted_25 = { class: "form-check" };
var _hoisted_26 = ["id", "value", "onChange"];
var _hoisted_27 = ["for"];
var _hoisted_28 = { class: "row filterUI-footer" };
var _hoisted_29 = { class: "select_title" };
var _hoisted_30 = { class: "result d-flex flex-wrap scrollbar" };
var _hoisted_31 = ["name", "value"];
var _hoisted_32 = { class: "text-info mx-3" };
var _hoisted_33 = ["onClick"];
var _hoisted_34 = ["name", "value"];
var _hoisted_35 = { class: "text-info mx-3" };
var _hoisted_36 = ["onClick"];
var _hoisted_37 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_38 = { class: "d-flex flex-sm-row align-items-center select-btns" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a, _b, _c;
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.type === 'task' ? _ctx.locales.interest_job : (_ctx.type === 'location' ? _ctx.locales.interest_location : '')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
                class: "dropdown-toggle position-relative",
                onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.toggleFilterPopup()); })
            }, [
                _createElementVNode("div", _hoisted_6, [
                    (_ctx.selectedCodes.length == 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.type === 'task' ? _ctx.locales.common_jobhint : (_ctx.type === 'location' ? _ctx.locales.common_locationhint : '')), 1))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.selectedCodes, function (code) {
                            return (_openBlock(), _createElementBlock("div", {
                                key: 'title_' + code.code,
                                class: "selected_code"
                            }, [
                                (code.parent_code != _ctx.type)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(code.parent ? code.parent['lang_' + _ctx.lang] : '') + " > " + _toDisplayString(code['lang_' + _ctx.lang]), 1))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(code['lang_' + _ctx.lang]) + " > " + _toDisplayString(_ctx.locales.common_all), 1)),
                                _createElementVNode("button", {
                                    class: "btn btn-sm",
                                    type: "button",
                                    "aria-label": "Close",
                                    onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                }, _cache[8] || (_cache[8] = [
                                    _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                ]), 8, _hoisted_10)
                            ]));
                        }), 128))
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("button", { type: "button" }, [
                    _createElementVNode("i", { class: "bi bi-chevron-right" })
                ], -1))
            ])
        ]),
        _createElementVNode("div", {
            class: "back_wrap",
            onClick: _cache[1] || (_cache[1] = function ($event) { return ([_ctx.toggleFilterPopup(), _ctx.initPendingCodes()]); })
        }, [
            _createVNode(_Transition, null, {
                default: _withCtx(function () { return [
                    (_ctx.filterVisible)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                        : _createCommentVNode("", true)
                ]; }),
                _: 1
            })
        ]),
        _withDirectives(_createElementVNode("div", {
            class: "filterUI",
            id: _ctx.type + 'Filter'
        }, [
            _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("span", {
                        class: "close_btn",
                        onClick: _cache[2] || (_cache[2] = function ($event) { return ([_ctx.toggleFilterPopup(), _ctx.initPendingCodes()]); })
                    }, _cache[10] || (_cache[10] = [
                        _createElementVNode("i", { class: "bi bi-x" }, null, -1)
                    ]))
                ])
            ]),
            _cache[15] || (_cache[15] = _createStaticVNode("<div class=\"filterUI-search\"><div class=\"input-group\"><span class=\"input-group-text border-end-0 bg-white\"><i class=\"bi bi-search\"></i></span><div style=\"flex:1 1 auto;\"><select type=\"search\" class=\"form-select border-start-0\" placeholder=\"직무 명칭을 검색해보세요.\" aria-describedby=\"basic-addon1\"></select></div></div></div>", 1)),
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, function (category) {
                            var _a;
                            return (_openBlock(), _createElementBlock("button", {
                                key: category.code,
                                class: _normalizeClass(["list-group-item list-group-item-action", { active: ((_a = _ctx.selectedCategory) === null || _a === void 0 ? void 0 : _a.code) == category.code }]),
                                type: "button",
                                onClick: function ($event) { return (_ctx.selectCategory(category)); }
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass({ select: _ctx.pendingCodes.some(function (code) { return code.code === category.code || code.parent_code === category.code; }) })
                                }, _toDisplayString(category['lang_' + _ctx.lang]), 3),
                                _cache[11] || (_cache[11] = _createElementVNode("i", { class: "bi bi-chevron-right" }, null, -1))
                            ], 10, _hoisted_18));
                        }), 128))
                    ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                        _withDirectives(_createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                                _withDirectives(_createElementVNode("input", {
                                    class: "form-check-input",
                                    id: 'all_' + ((_a = _ctx.selectedCategory) === null || _a === void 0 ? void 0 : _a.code),
                                    type: "checkbox",
                                    value: _ctx.selectedCategory,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((_ctx.pendingCodes) = $event); }),
                                    onChange: _cache[4] || (_cache[4] = function ($event) { return (_ctx.selectCategoryCode($event, _ctx.selectedCategory)); })
                                }, null, 40, _hoisted_23), [
                                    [_vModelCheckbox, _ctx.pendingCodes]
                                ]),
                                _createElementVNode("label", {
                                    class: "form-check-label",
                                    for: 'all_' + ((_b = _ctx.selectedCategory) === null || _b === void 0 ? void 0 : _b.code)
                                }, _toDisplayString(_ctx.locales.common_all), 9, _hoisted_24)
                            ])
                        ], 512), [
                            [_vShow, _ctx.selectedCategory]
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_c = _ctx.selectedCategory) === null || _c === void 0 ? void 0 : _c.children, function (code) {
                            return (_openBlock(), _createElementBlock("div", {
                                class: "filterUI-body-list-item",
                                key: code.code
                            }, [
                                _createElementVNode("div", _hoisted_25, [
                                    _withDirectives(_createElementVNode("input", {
                                        class: "form-check-input",
                                        id: code.code,
                                        type: "checkbox",
                                        value: code,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) { return ((_ctx.pendingCodes) = $event); }),
                                        onChange: function ($event) { return (_ctx.selectCode($event, code)); }
                                    }, null, 40, _hoisted_26), [
                                        [_vModelCheckbox, _ctx.pendingCodes]
                                    ]),
                                    _createElementVNode("label", {
                                        class: "form-check-label",
                                        for: code.code
                                    }, _toDisplayString(code['lang_' + _ctx.lang]), 9, _hoisted_27)
                                ])
                            ]));
                        }), 128))
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                    _createTextVNode(_toDisplayString(_ctx.locales.common_selected) + " " + _toDisplayString(_ctx.title) + " ", 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.pendingCodes.length), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingCodes, function (code) {
                        return (_openBlock(), _createElementBlock("div", {
                            key: 'selected_' + code.code,
                            class: "d-flex align-items-center"
                        }, [
                            (code.parent_code != _ctx.type)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("input", {
                                        type: "hidden",
                                        name: _ctx.type + '_filter[]',
                                        value: code.code
                                    }, null, 8, _hoisted_31),
                                    _createElementVNode("span", _hoisted_32, _toDisplayString(code.parent ? code.parent['lang_' + _ctx.lang] : '') + " > " + _toDisplayString(code['lang_' + _ctx.lang]), 1),
                                    _createElementVNode("button", {
                                        class: "btn-close btn btn-sm",
                                        type: "button",
                                        "aria-label": "Close",
                                        onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                    }, _cache[12] || (_cache[12] = [
                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                    ]), 8, _hoisted_33)
                                ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createElementVNode("input", {
                                        type: "hidden",
                                        name: _ctx.type + '_category_filter[]',
                                        value: code.code
                                    }, null, 8, _hoisted_34),
                                    _createElementVNode("span", _hoisted_35, _toDisplayString(code['lang_' + _ctx.lang]) + " > " + _toDisplayString(_ctx.locales.common_all), 1),
                                    _createElementVNode("button", {
                                        class: "btn-close btn btn-sm",
                                        type: "button",
                                        "aria-label": "Close",
                                        onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                    }, _cache[13] || (_cache[13] = [
                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                    ]), 8, _hoisted_36)
                                ], 64))
                        ]));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                        _createElementVNode("button", {
                            class: "btn btn-sm py-0",
                            type: "button",
                            onClick: _cache[6] || (_cache[6] = function ($event) { return (_ctx.resetFilter()); })
                        }, [
                            _cache[14] || (_cache[14] = _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.locales.common_reset), 1)
                        ]),
                        _createElementVNode("button", {
                            class: "btn btn-primary text-white",
                            type: "button",
                            onClick: _cache[7] || (_cache[7] = function ($event) { return (_ctx.setCode()); })
                        }, _toDisplayString(_ctx.locales.common_set), 1)
                    ])
                ])
            ])
        ], 8, _hoisted_12), [
            [_vShow, _ctx.filterVisible]
        ])
    ]));
}
