import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = {
    key: 0,
    class: "popup_background"
};
var _hoisted_2 = { class: "filterUI" };
var _hoisted_3 = { class: "filterUI-title" };
var _hoisted_4 = { class: "title-line d-flex justify-content-between align-items-center" };
var _hoisted_5 = { class: "row filterUI-body" };
var _hoisted_6 = { class: "col-4 border-end h-100" };
var _hoisted_7 = { class: "filterUI-body-left list-group list-group-flush filterUI-body-category scrollbar" };
var _hoisted_8 = ["onClick"];
var _hoisted_9 = { class: "filterUI-body-right col h-100 scrollbar" };
var _hoisted_10 = { class: "row filterUI-body-list" };
var _hoisted_11 = { class: "form-check" };
var _hoisted_12 = ["id", "value", "onChange"];
var _hoisted_13 = ["for"];
var _hoisted_14 = { class: "count" };
var _hoisted_15 = { class: "row filterUI-footer" };
var _hoisted_16 = { class: "select_title" };
var _hoisted_17 = { class: "result d-flex flex-wrap scrollbar" };
var _hoisted_18 = { class: "all" };
var _hoisted_19 = { class: "text-info mx-3" };
var _hoisted_20 = ["value"];
var _hoisted_21 = { class: "text-info mx-3" };
var _hoisted_22 = ["onClick"];
var _hoisted_23 = { key: 0 };
var _hoisted_24 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_25 = { class: "d-flex flex-sm-row align-items-center select-btns" };
var _hoisted_26 = {
    class: "btn btn-primary text-white",
    type: "submit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("button", {
            type: "button",
            class: "etc_btn btn position-relative",
            onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.toggleFilterPopup()); })
        }, [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "bi bi-sliders" }, null, -1)),
            _withDirectives(_createElementVNode("span", { class: "position-absolute top-0 start-100 translate-middle badge rounded-circle filter-count-badge" }, _toDisplayString(_ctx.selectedCodes.length), 513), [
                [_vShow, _ctx.selectedCodes.length > 0]
            ])
        ]),
        _createElementVNode("div", {
            class: "back_wrap",
            onClick: _cache[1] || (_cache[1] = function ($event) { return ([_ctx.toggleFilterPopup(), _ctx.initPendingCodes()]); })
        }, [
            _createVNode(_Transition, null, {
                default: _withCtx(function () { return [
                    (_ctx.filterVisible)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                        : _createCommentVNode("", true)
                ]; }),
                _: 1
            })
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.locales.common_filter), 1),
                    _createElementVNode("span", {
                        class: "close_btn",
                        onClick: _cache[2] || (_cache[2] = function ($event) { return ([_ctx.toggleFilterPopup(), _ctx.initPendingCodes()]); })
                    }, _cache[7] || (_cache[7] = [
                        _createElementVNode("i", { class: "bi bi-x" }, null, -1)
                    ]))
                ])
            ]),
            _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"filterUI-search\"><div class=\"input-group\"><span class=\"input-group-text border-end-0 bg-white\"><i class=\"bi bi-search\"></i></span><div style=\"flex:1 1 auto;\"><select type=\"search\" class=\"form-select border-start-0\" placeholder=\"직무 명칭을 검색해보세요.\" aria-describedby=\"basic-addon1\"></select></div></div></div>", 1)),
            _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("button", {
                            class: _normalizeClass(["list-group-item list-group-item-action", _ctx.displayForDyslexic ? 'select' : '']),
                            type: "button",
                            onClick: _cache[3] || (_cache[3] = function ($event) { return (_ctx.resetForDyslexic()); })
                        }, _toDisplayString(_ctx.locales.common_all), 3),
                        (_ctx.categories.length > 0)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.menus, function (category_name) {
                                var _a;
                                return (_openBlock(), _createElementBlock("button", {
                                    key: category_name,
                                    class: _normalizeClass(["list-group-item list-group-item-action", { active: ((_a = _ctx.selectedCategory) === null || _a === void 0 ? void 0 : _a.code) == _ctx.convertCategoryCode(category_name) }]),
                                    type: "button",
                                    onClick: function ($event) { return (_ctx.selectCategory(_ctx.getCategory(category_name))); }
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass({ select: _ctx.pendingCodes.some(function (code) { return code.code === _ctx.convertCategoryCode(category_name) || code.parent_code === _ctx.convertCategoryCode(category_name); }) })
                                    }, _toDisplayString(_ctx.getCategory(category_name)['lang_' + _ctx.lang]), 3),
                                    _cache[8] || (_cache[8] = _createElementVNode("i", { class: "bi bi-chevron-right" }, null, -1))
                                ], 10, _hoisted_8));
                            }), 128))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.selectedCategory) === null || _a === void 0 ? void 0 : _a.children, function (code) {
                            return (_openBlock(), _createElementBlock("div", {
                                class: "filterUI-body-list-item",
                                key: code.code
                            }, [
                                _createElementVNode("div", _hoisted_11, [
                                    _withDirectives(_createElementVNode("input", {
                                        class: "form-check-input",
                                        id: code.code,
                                        type: "checkbox",
                                        value: code,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((_ctx.pendingCodes) = $event); }),
                                        onChange: function ($event) { return (_ctx.selectCode($event, code)); }
                                    }, null, 40, _hoisted_12), [
                                        [_vModelCheckbox, _ctx.pendingCodes]
                                    ]),
                                    _createElementVNode("label", {
                                        class: "form-check-label",
                                        for: code.code
                                    }, [
                                        _createTextVNode(_toDisplayString(code['lang_' + _ctx.lang]) + " ", 1),
                                        _createElementVNode("span", _hoisted_14, _toDisplayString(new Intl.NumberFormat().format(code.recruit_metas_count)), 1)
                                    ], 8, _hoisted_13)
                                ])
                            ]));
                        }), 128))
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                    _createTextVNode(_toDisplayString(_ctx.locales.common_selected) + " " + _toDisplayString(_ctx.locales.common_filter) + " ", 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.pendingCodes.length), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.locales.common_all), 1)
                    ], 512), [
                        [_vShow, _ctx.displayForDyslexic]
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pendingCodes, function (code) {
                        return (_openBlock(), _createElementBlock("div", {
                            key: 'selected_' + code.code,
                            class: "d-flex align-items-center"
                        }, [
                            (_ctx.menus.indexOf(code.code) < 0)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("input", {
                                        type: "hidden",
                                        name: "etc_filter[]",
                                        value: code.code
                                    }, null, 8, _hoisted_20),
                                    _createElementVNode("span", _hoisted_21, _toDisplayString(code.parent ? code.parent['lang_' + _ctx.lang] : '') + " > " + _toDisplayString(code['lang_' + _ctx.lang]), 1),
                                    _createElementVNode("button", {
                                        class: "btn-close btn btn-sm",
                                        type: "button",
                                        "aria-label": "Close",
                                        onClick: function ($event) { return (_ctx.removeSelectedCode(code)); }
                                    }, _cache[9] || (_cache[9] = [
                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                    ]), 8, _hoisted_22)
                                ], 64))
                                : _createCommentVNode("", true)
                        ]));
                    }), 128)),
                    (_ctx.pendingCodes.length == 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[10] || (_cache[10] = [
                            _createElementVNode("input", {
                                type: "hidden",
                                name: "etc_filter[]",
                                value: ""
                            }, null, -1),
                            _createElementVNode("input", {
                                type: "hidden",
                                name: "etc_category_filter[]",
                                value: ""
                            }, null, -1)
                        ])))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("button", {
                            class: "btn btn-sm py-0",
                            type: "button",
                            onClick: _cache[5] || (_cache[5] = function ($event) { return (_ctx.resetFilter()); })
                        }, [
                            _cache[11] || (_cache[11] = _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.locales.common_reset), 1)
                        ]),
                        _createElementVNode("button", _hoisted_26, _toDisplayString(_ctx.locales.common_search), 1)
                    ])
                ])
            ])
        ], 512), [
            [_vShow, _ctx.filterVisible]
        ])
    ]));
}
