<template>
	<div>
		<div class="main-container">
			<div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
				<div class="editor-container__editor">
					<div ref="editorElement">
						<ckeditor 
							v-if="isLayoutReady" 
							v-model="content" 
							:editor="editor" 
							:config="config"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
.ck-editor__editable_inline {
    min-height: 400px;
}
</style>


<script>
import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	AutoImage,
	Autosave,
	BlockQuote,
	Bold,
	CKBox,
	CKBoxImageEdit,
	CloudServices,
	Essentials,
	Heading,
	ImageBlock,
	ImageCaption,
	ImageInline,
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageTextAlternative,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	PictureEditing,
	SelectAll,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Alignment,

} from 'ckeditor5';

import translations from 'ckeditor5/translations/ko.js';

import 'ckeditor5/ckeditor5.css';

import { ref, watch, defineEmits } from 'vue';

/**
 * Please update the following values with your actual tokens.
 * Instructions on how to obtain them: https://ckeditor.com/docs/trial/latest/guides/real-time/quick-start.html
 */
const CKBOX_TOKEN_URL = 'https://120835.cke-cs.com/token/dev/bqxeMqftEA5o8oeoesx2YRzCzbkbzSwOOt00?limit=10';

export default {
	name: 'app',
	data() {
		return {
			isLayoutReady: false,
			config: null, // CKEditor needs the DOM tree before calculating the configuration.
			editor: ClassicEditor,
			content: ""
		};
	},
	props: {
		modelValue: {
			type: String,
			required: true
		},
	},
	
	setup(props, {emit}) {
		let content = ref("");

		watch(content, (newValue, oldValue) => {
			emit('update:modelValue', newValue);
		});
		let isInited = ref(false);
		watch(() => props.modelValue, (newValue, oldValue) => {
			if(isInited.value == false) {
				content.value = newValue;
				isInited.value = true;
			}

		})

		return {
			content
		}
	},
	emits: ['update:modelValue'],
	mounted() {
		this.config = {
			toolbar: {
				items: [
					'undo',
					'redo',
					'|',
					'heading',
					'|',
					'bold',
					'italic',
					'underline',
					'|',
					'link',
					'insertImage',
					'ckbox',
					'mediaEmbed',
					'insertTable',
					'blockQuote',
					'|',
					'alignment',
					'|',
					'fontSize',
					'fontFamily',
					'fontColor',
					'fontBackgroundColor',
					'|',
					'bulletedList',
					'numberedList',
					'todoList',
					'outdent',
					'indent'
				],
			},
			mediaEmbed: {
				previewsInData: true
			},
			plugins: [
				AccessibilityHelp,
				Autoformat,
				AutoImage,
				Autosave,
				BlockQuote,
				Bold,
				CKBox,
				CKBoxImageEdit,
				CloudServices,
				Essentials,
				Heading,
				ImageBlock,
				ImageCaption,
				ImageInline,
				ImageInsert,
				ImageInsertViaUrl,
				ImageResize,
				ImageStyle,
				ImageTextAlternative,
				ImageToolbar,
				ImageUpload,
				Indent,
				IndentBlock,
				Italic,
				Link,
				LinkImage,
				List,
				ListProperties,
				MediaEmbed,
				Paragraph,
				PasteFromOffice,
				PictureEditing,
				SelectAll,
				Table,
				TableCaption,
				TableCellProperties,
				TableColumnResize,
				TableProperties,
				TableToolbar,
				TextTransformation,
				TodoList,
				Underline,
				Undo,
				FontBackgroundColor,
				FontColor,
				FontFamily,
				FontSize,
				Alignment

			],
			ckbox: {
				tokenUrl: CKBOX_TOKEN_URL
			},
			heading: {
				options: [
					{
						model: 'paragraph',
						title: 'Paragraph',
						class: 'ck-heading_paragraph'
					},
					{
						model: 'heading1',
						view: 'h1',
						title: 'Heading 1',
						class: 'ck-heading_heading1'
					},
					{
						model: 'heading2',
						view: 'h2',
						title: 'Heading 2',
						class: 'ck-heading_heading2'
					},
					{
						model: 'heading3',
						view: 'h3',
						title: 'Heading 3',
						class: 'ck-heading_heading3'
					},
					{
						model: 'heading4',
						view: 'h4',
						title: 'Heading 4',
						class: 'ck-heading_heading4'
					},
					{
						model: 'heading5',
						view: 'h5',
						title: 'Heading 5',
						class: 'ck-heading_heading5'
					},
					{
						model: 'heading6',
						view: 'h6',
						title: 'Heading 6',
						class: 'ck-heading_heading6'
					}
				]
			},
			image: {
				toolbar: [
					'toggleImageCaption',
					'imageTextAlternative',
					'|',
					'imageStyle:inline',
					'imageStyle:wrapText',
					'imageStyle:breakText',
					'|',
					'resizeImage',
					'|',
					'ckboxImageEdit'
				]
			},
			language: 'ko',
			link: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				decorators: {
					toggleDownloadable: {
						mode: 'manual',
						label: 'Downloadable',
						attributes: {
							download: 'file'
						}
					}
				}
			},
			list: {
				properties: {
					styles: true,
					startIndex: true,
					reversed: true
				}
			},
			placeholder: '내용을 입력해 주세요',
			table: {
				contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
			},
			translations: [translations],
			
		};

		configUpdateAlert(this.config);

		this.isLayoutReady = true;
	},
	methods: {
		
	}
};

/**
 * This function exists to remind you to update the config needed for premium features.
 * The function can be safely removed. Make sure to also remove call to this function when doing so.
 */
function configUpdateAlert(config) {
	if (configUpdateAlert.configUpdateAlertShown) {
		return;
	}

	const isModifiedByUser = (currentValue, forbiddenValue) => {
		if (currentValue === forbiddenValue) {
			return false;
		}

		if (currentValue === undefined) {
			return false;
		}

		return true;
	};

	const valuesToUpdate = [];

	configUpdateAlert.configUpdateAlertShown = true;

	if (!isModifiedByUser(config.ckbox?.tokenUrl, 'https://120835.cke-cs.com/token/dev/bqxeMqftEA5o8oeoesx2YRzCzbkbzSwOOt00?limit=10')) {
		valuesToUpdate.push('https://120835.cke-cs.com/token/dev/bqxeMqftEA5o8oeoesx2YRzCzbkbzSwOOt00?limit=10');
	}

	if (valuesToUpdate.length) {
		console.warn(
			[
				'Please update the following values in your editor config',
				'in order to receive full access to the Premium Features:',
				'',
				...valuesToUpdate.map(value => ` - ${value}`)
			].join('\n')
		);
	}
}
</script>

