import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = ["id", "value", "disabled"];
var _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (option) {
            return (_openBlock(), _createElementBlock("div", {
                key: option.code,
                class: "form-check form-check-inline"
            }, [
                _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    type: "checkbox",
                    id: 'code' + option.code,
                    value: option,
                    disabled: !_ctx.editable,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.selectedOptions) = $event); }),
                    onChange: _cache[1] || (_cache[1] =
                        //@ts-ignore
                        function () {
                            var args = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                args[_i] = arguments[_i];
                            }
                            return (_ctx.updateCheckedOption && _ctx.updateCheckedOption.apply(_ctx, args));
                        })
                }, null, 40, _hoisted_1), [
                    [_vModelCheckbox, _ctx.selectedOptions]
                ]),
                _createElementVNode("label", {
                    class: "form-check-label",
                    for: 'code' + option.code
                }, _toDisplayString(option.lang_ko), 9, _hoisted_2)
            ]));
        }), 128))
    ]));
}
