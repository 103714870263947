var RecruitTextMeta = /** @class */ (function () {
    function RecruitTextMeta() {
        this.id = 0;
        this.recruit_id = 0;
        this.lang = '';
        this.mgmt_code = '';
        this.meta = {
            title: '',
            desc: '',
            work_type_extra: '',
            work_duration_extra: '',
            work_time: '',
            work_date_extra: '',
            work_paytype_extra: '',
            work_etc: '',
            work_etc_extra: '',
            work_overview: '',
            welfare_etc: '',
            con_docu_extra: '',
            con_language_extra: '',
            career: '',
            con_sex_extra: '',
            con_etc: '',
            prefer_license: '',
            prefer_etc: '',
        };
    }
    return RecruitTextMeta;
}());
export { RecruitTextMeta };
