var RecruitSubmitForm = /** @class */ (function () {
    function RecruitSubmitForm(recruit) {
        this.lang = 'ko';
        this.code_metas = {
            location: [],
            task: [],
            work_type: [],
            work_duration: [],
            work_date: [],
            work_paytype: [],
            welfare_insurance: [],
            welfare_meals: [],
            welfare_trans: [],
            welfare_lodging: [],
            welfare_bonus: [],
            welfare_vacation: [],
            con_docu: [],
            con_visa: [],
            con_language: [],
            con_sex: [],
            con_age: [],
            con_edu: [],
            info_country: [],
        };
        this.common_text_metas = {
            use_deadline: false,
            closed_at: '',
            company_name: '',
            is_consigned_company: false,
            company_address1: '',
            company_address2: '',
            contact_name: '',
            contact_phone: '',
            contact_mail: '',
            work_payment: 0,
            action_phone: '',
            use_action_phone: false,
            action_site: '',
            use_action_site: false,
            action_email: '',
            use_action_email: false,
            is_certified: false,
        };
        this.text_metas = {
            title: '',
            desc: '',
            work_type_extra: '',
            work_duration_extra: '',
            work_date_extra: '',
            work_paytype_extra: '',
            work_etc: '',
            work_etc_extra: '',
            work_overview: '',
            welfare_etc: '',
            con_docu_extra: '',
            con_language_extra: '',
            career: '',
            con_etc: '',
            prefer_license: '',
            prefer_etc: '',
        };
        this.file_metas = {
            company_photos: []
        };
        var self = this;
        self.lang = recruit.lang;
        var _loop_1 = function (field) {
            var codes = recruit.code_metas[field];
            codes.forEach(function (code) {
                self.code_metas[field].push(code.code);
            });
        };
        for (var field in recruit.code_metas) {
            _loop_1(field);
        }
        for (var field in recruit.common_text_metas.meta) {
            var value = recruit.common_text_metas.meta[field];
            self.common_text_metas[field] = value;
        }
        for (var field in recruit.text_metas.meta) {
            var value = recruit.text_metas.meta[field];
            self.text_metas[field] = value;
        }
        recruit.file_metas.company_photos.forEach(function (image) {
            self.file_metas.company_photos.push(image);
        });
    }
    return RecruitSubmitForm;
}());
export { RecruitSubmitForm };
