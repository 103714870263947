import './bootstrap';
import 'slick-carousel';

window.Swal = require('sweetalert2');
window.$ = window.jQuery = require('jquery');
window.bootstrap = require("bootstrap");
window.Popper = require("@popperjs/core");
window.lightbox = require('lightbox2');
require("select2");
require('jquery-serializejson');

window.Spinner = require('spin.js');
import {startSpinner} from "@/ts/modules/startSpinner.ts";
import { ServiceLanguages } from "@/ts/enums/ServiceLanguages";

import { createI18n } from 'vue-i18n'
let  initLocale = {};
for(let lang in Object.values(ServiceLanguages)) {
	initLocale[lang] = {};
}
const i18n = createI18n({
	locale: document.head.querySelector('meta[name="App-Language"]').content, // 현재 언어 세팅
	fallbackLocale: document.head.querySelector('meta[name="App-Language"]').content,
	messages: initLocale,
})
  

// import Vue from 'vue';
import { createApp } from 'vue'

import RecruitFilter from './components/RecruitFilter.vue';
import RecruitEtcFilter from './components/RecruitEtcFilter.vue';
import RecruitFilters from './components/RecruitFilters.vue';
import MobileRecruitFilter from './components/MobileRecruitFilter.vue';
import MobileRecruitEtcFilter from './components/MobileRecruitEtcFilter.vue';
import MobileRecruitFilters from './components/MobileRecruitFilters.vue';
import RecruitCreateVue from './components/RecruitCreate.vue';
import InterestFilters from './components/InterestFilters.vue';
import InterestFilter from './components/InterestFilter.vue';
import InterestEtcFilter from './components/InterestEtcFilter.vue';
import MobileInterestFilters from './components/MobileInterestFilters.vue';
import MobileInterestFilter from './components/MobileInterestFilter.vue';
import MobileInterestEtcFilter from './components/MobileInterestEtcFilter.vue';
import ResumeRadio from './components/ResumeRadio.vue';
import ResumeCheck from './components/ResumeCheck.vue';
import ResumeFilter from './components/ResumeFilter.vue';
import ResumeFilters from './components/ResumeFilters.vue';
import MobileResumeFilter from './components/MobileResumeFilter.vue';
import MobileResumeFilters from './components/MobileResumeFilters.vue';
import ResumeSelectBox from './components/ResumeSelectBox.vue';
import MobileResumeSelectBox from './components/MobileResumeSelectBox.vue';
import FileUploadComponent from "@/js/components/FileUploadComponent.vue";
import RecruitList from "@/js/components/RecruitList.vue";


import ResumePage from './components/ResumePage.vue';
import ApplicationPage from './components/ApplicationPage.vue';

const appContainer = document.getElementById('app');

// 기존 Vue 인스턴스가 있는 경우 언마운트
if (appContainer.__vue_app__) {
	appContainer.__vue_app__.unmount();
}

// 새로운 Vue 인스턴스 마운트
const app = createApp({});

app.component('recruit-filter', RecruitFilter);
app.component('recruit-etc-filter', RecruitEtcFilter);
app.component('recruit-filters', RecruitFilters);
app.component('m-recruit-filter', MobileRecruitFilter);
app.component('m-recruit-etc-filter', MobileRecruitEtcFilter);
app.component('m-recruit-filters', MobileRecruitFilters);
app.component('recruit-create-vue', RecruitCreateVue);
app.component('interest-filters', InterestFilters);
app.component('interest-filter', InterestFilter);
app.component('interest-etc-filter', InterestEtcFilter);
app.component('m-interest-filters', MobileInterestFilters);
app.component('m-interest-filter', MobileInterestFilter);
app.component('m-interest-etc-filter', MobileInterestEtcFilter);
app.component('resume-radio', ResumeRadio);
app.component('resume-check', ResumeCheck);
app.component('resume-filters', ResumeFilters);
app.component('resume-filter', ResumeFilter);
app.component('m-resume-filters', MobileResumeFilters);
app.component('m-resume-filter', MobileResumeFilter);
app.component('resume-page', ResumePage);
app.component('resume-select-box', ResumeSelectBox);
app.component('m-resume-select-box', MobileResumeSelectBox);
app.component('application-page', ApplicationPage);
app.component('file-upload-component', FileUploadComponent);
app.component('recruit-list', RecruitList);


app.use(i18n);

import 'ckeditor5/ckeditor5.css';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
app.use(CkeditorPlugin);

app.mount('#app');
document.getElementById('app')?.removeAttribute('v-cloak');

//각종 모달 뒷배경 스크롤 방지
	$('.back_wrap, .popup_background').on('scroll touchmove mousewheel', function(event) { // 터치무브와 마우스휠 스크롤 방지
		event.preventDefault();
		event.stopPropagation();
		return false;
	});

	//구직자등록버튼 언어감지
	$(document).ready(function(){
		let lang = document.documentElement.lang;
		let map = {
			ko : 'ibwryrP6zaNU3Em86',
			en : 'CF4NKum6EUb3TBT77',
			vi : 'CF4NKum6EUb3TBT77',
			mn : 'CF4NKum6EUb3TBT77',
			uz : 'CF4NKum6EUb3TBT77',
			id : 'CF4NKum6EUb3TBT77',
			th : 'CF4NKum6EUb3TBT77',
			ne : 'CF4NKum6EUb3TBT77',
			ja : 'CF4NKum6EUb3TBT77',
			zh : 'CF4NKum6EUb3TBT77',
			};
		if(!map[lang]) {
			alert("언어가 감지되지 않습니다");
		}
		$('.enroll').attr('href', 'https://forms.gle/' + map[lang]);
	})

	//floating btn(모바일 모달 fade처리 포함)
	$('.floating_btn, .floating_btn_group .popup_background').click(function(){
		$('.floating_btn_group').toggleClass('show');
		$('.floating_btn_group .popup_background').fadeToggle(150, 'linear')
	})

	//상세페이지 모집조건, 근무조건 하위메뉴 하나일 때 너비 100%로 고정
	$(document).ready(function(){
		$('.recruit-field-table>.row').each(function(){
			let col = $(this).children('div')
			if(col.length < 2){
				col.addClass('col-12');
				col.removeClass('col-6');
			}
		});
	});

	//input 유효성 검사
	(() => {
		'use strict'
	
		const forms = document.querySelectorAll('.needs-validation')
	
		Array.from(forms).forEach(form => {
			form.addEventListener('submit', event => {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}
	
				form.classList.add('was-validated')
			}, false)
		})
	})()

	//password input eye icon
	$(function(){
		$('.eyes').on('click',function(){
			const thisInput = $(this).parents('.input-group').find('input')

			thisInput.toggleClass('active');
			if(thisInput.hasClass('active') == true ){
				$(this).find('.bi-eye-fill').attr('class',"bi bi-eye-slash-fill").parents('.input-group').find('input').attr('type',"text");
			} else{
				$(this).find('.bi-eye-slash-fill').attr('class',"bi bi-eye-fill").parents('.input-group').find('input').attr('type','password');
		}});
	});

	//로그아웃
	$('.logout_btn').click(function(){
		let lang = document.documentElement.lang;

		axios.post('/logout', {})
		.then(({data}) => {
			console.log(data)
			localStorage.removeItem('email');
			localStorage.removeItem('token');
		})
		.catch(({response}) => {
			console.log(response.data.message);
		})	
		.finally(() => {
			window.location.href=goHome;
		})
	})

	//캐시 된 페이지 로드된 경우 강제 리로드하도록 처리
	window.addEventListener('pageshow', function(event) {
		if (event.persisted) {
			window.location.reload();
		}
	});


	//scroll sticky
	window.onscroll = function() {
		if (typeof stickyTab !== "undefined" && typeof topHeight !== "undefined") {
			const windowTop = window.scrollY;
	
			if (windowTop >= topHeight) {
				stickyTab.classList.add("scroll_sticky");
				if (typeof container_top !== "undefined") {
					container_top.classList.add("top");
				}
			} else {
				stickyTab.classList.remove("scroll_sticky");
				if (typeof container_top !== "undefined") {
					container_top.classList.remove("top");
				}
			}
		}
	}

	window.validateMobileInput = function(input) {
        const regex = /^[0-9+\-]*$/; // 허용된 문자: 숫자, +, -
        const value = input.value;

        if (!regex.test(value)) {
            // 유효하지 않은 문자는 제거
            input.value = value.replace(/[^0-9+\-]/g, '');
        }
    };

	document.addEventListener("DOMContentLoaded", function () {
		const referUrlButtons = document.querySelectorAll(".floating_btns a, .companyinfo_reg_btn");
	
		referUrlButtons.forEach(function (btn) {
			btn.addEventListener("click", function () {
				const currentUrl = window.location.href;
				localStorage.setItem("refer_url", currentUrl);
			});
		});
	});