var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = { class: "droupdown_input" };
var _hoisted_2 = ["name"];
var _hoisted_3 = ["id", "value", "placeholder"];
var _hoisted_4 = ["for"];
var _hoisted_5 = {
    key: 0,
    class: "dropdown_menu"
};
var _hoisted_6 = { class: "dropdown_search" };
var _hoisted_7 = ["placeholder"];
var _hoisted_8 = ["onClick", "data-index"];
var _hoisted_9 = {
    key: 0,
    class: "bi bi-check-lg"
};
var _hoisted_10 = {
    key: 1,
    class: "invalid-feedback"
};
import { ref, watch, computed, onBeforeUnmount, onMounted, nextTick } from 'vue';
var __default__ = {
    name: 'resume-select-box',
};
export default /*@__PURE__*/ _defineComponent(__assign(__assign({}, __default__), { props: {
        type: {
            required: true,
            type: String,
        },
        initValue: {
            required: false,
            type: String
        },
        options: {
            required: true,
            type: (Array)
        },
        hintText: {
            required: true,
            type: String
        },
        searchHint: {
            required: false,
            type: String
        },
        errText: {
            required: true,
            type: String
        },
        /**
        * 빈 값인 선택 옵션 표시 여부
        */
        displayEmptyOption: {
            required: false,
            type: Boolean,
            default: true
        },
        /**
         * 빈 값인 선택 옵션의 텍스트
         */
        emptyOptionText: {
            required: false,
            type: String,
            default: "None"
        },
        isOpen: {
            required: false,
            type: Boolean,
            default: false
        }
    }, emits: ['toggle', 'update:selected-value'], setup: function (__props, _a) {
        var __emit = _a.emit;
        var lang = document.documentElement.lang;
        var isOpen = ref(false);
        var isInvalid = ref(false);
        var dropdownRef = ref(null);
        var dropdownMenu = ref(null);
        var searchInputRef = ref(null);
        var activeIndex = ref(0);
        var isComposing = ref(false);
        var props = __props;
        var selected = ref("");
        var selectedLabel = ref("");
        var searchQuery = ref("");
        var previousSearchQuery = "";
        var emit = __emit;
        var onKeyup = function (event) {
            var currentQuery = event.target.value;
            // 검색어가 변경되었는지 확인
            if (currentQuery !== previousSearchQuery) {
                activeIndex.value = 0;
                previousSearchQuery = currentQuery;
            }
            searchQuery.value = currentQuery;
        };
        // 화살표 키 등 키다운 이벤트에서 처리
        var onKeydown = function (event) {
            if (isComposing.value)
                return;
            if (event.key === 'Enter' && filteredOptionsWithEmpty.value.length > 0) {
                var option = filteredOptionsWithEmpty.value[activeIndex.value];
                // option이 ServiceCode 형식인지 확인
                if (option && typeof option === 'object') {
                    selectOption(option);
                }
            }
            else if (event.key === 'ArrowDown') {
                event.preventDefault();
                activeIndex.value = Math.min(activeIndex.value + 1, filteredOptionsWithEmpty.value.length - 1);
                scrollToActiveItem();
            }
            else if (event.key === 'ArrowUp') {
                event.preventDefault();
                activeIndex.value = Math.max(activeIndex.value - 1, 0);
                scrollToActiveItem();
            }
        };
        // 한글 입력 완료 시 발생
        var handleCompositionEnd = function (event) {
            isComposing.value = false;
            searchQuery.value = event.target.value;
        };
        // 스크롤 이동 함수
        var scrollToActiveItem = function () {
            var dropdownMenuEl = dropdownMenu.value;
            var headerOffset = 64; // 상단 서치바 높이 64px
            if (dropdownMenuEl) {
                var activeElement = dropdownMenuEl.querySelectorAll('.dropdown_item')[activeIndex.value];
                if (activeElement) {
                    var dropdownHeight = dropdownMenuEl.clientHeight;
                    var itemHeight = activeElement.clientHeight;
                    var scrollOffset = activeElement.offsetTop - headerOffset; // 여백을 뺀 offset 계산
                    if (scrollOffset + itemHeight > dropdownMenuEl.scrollTop + dropdownHeight) {
                        // 아이템이 보이는 영역보다 아래에 있을 때
                        dropdownMenuEl.scrollTop = scrollOffset + itemHeight - dropdownHeight;
                    }
                    else if (scrollOffset < dropdownMenuEl.scrollTop) {
                        // 아이템이 보이는 영역보다 위에 있을 때
                        dropdownMenuEl.scrollTop = scrollOffset;
                    }
                }
            }
        };
        watch([function () { return props.initValue; }, function () { return props.options; }], function (_a) {
            var newInitValue = _a[0], newOptions = _a[1];
            console.log('newInitValue:', newInitValue);
            // 아무 값도 선택되지 않은 경우 (newInitValue가 null, undefined, 또는 빈 문자열)
            if (newInitValue === null || newInitValue === undefined) {
                selectedLabel.value = ''; // 아무 값도 표시하지 않음
            }
            // "없음"(emptyOptionText)이 명시적으로 선택된 경우에만 표시
            else if (newInitValue === props.emptyOptionText || newInitValue === '') {
                selectedLabel.value = props.emptyOptionText; // "없음"으로 표시
            }
            // 선택된 다른 값이 있을 때 (옵션이 선택된 경우)
            else {
                selected.value = newInitValue;
                var selectedOption = newOptions.find(function (option) { return option.code === selected.value; });
                if (selectedOption) {
                    selectedLabel.value = selectedOption["lang_".concat(lang)]; // 선택된 옵션 표시
                }
                else {
                    selectedLabel.value = ''; // 옵션이 없는 경우 아무것도 표시하지 않음
                }
            }
        });
        var toggleDropdown = function () {
            if (isOpen.value) {
                isOpen.value = false;
            }
            else {
                isOpen.value = true;
                nextTick(function () {
                    var _a;
                    (_a = searchInputRef.value) === null || _a === void 0 ? void 0 : _a.focus();
                });
            }
            emit('toggle', isOpen.value);
        };
        var selectOption = function (option) {
            // 빈 값 처리: code가 없을 경우 빈 값으로 처리
            if (!option.code) {
                selected.value = "";
                selectedLabel.value = props.emptyOptionText;
                emit('update:selected-value', '');
            }
            else {
                // ServiceCode 타입이 전달된 경우 처리
                selected.value = option.code;
                selectedLabel.value = option["lang_".concat(lang)];
                emit('update:selected-value', selected.value);
            }
            isOpen.value = false;
            validateSelection();
        };
        // 빈 값이 있는 옵션을 포함한 filteredOptions
        var filteredOptionsWithEmpty = computed(function () {
            var _a;
            var lowerCaseQuery = searchQuery.value.toLowerCase();
            var isInitialSearch = isKoreanInitial(lowerCaseQuery); // 입력된 문자열이 초성인지 여부
            var filtered = props.options.filter(function (option) {
                var optionLabel = option["lang_".concat(lang)].toLowerCase();
                var optionInitials = extractInitials(option["lang_".concat(lang)].toLowerCase());
                // 초성 검색일 경우 초성으로 필터, 아니면 전체 텍스트로 필터
                if (isInitialSearch) {
                    return optionInitials.startsWith(lowerCaseQuery); // 초성 매칭
                }
                else {
                    return optionLabel.includes(lowerCaseQuery); // 전체 문자 매칭
                }
            });
            // 빈 값 옵션도 검색 필터 대상에 포함
            if (props.displayEmptyOption) {
                var emptyOptionInitials = extractInitials(props.emptyOptionText.toLowerCase()); // 초성 추출
                var emptyOption = (_a = {
                        code: ''
                    },
                    _a["lang_".concat(lang)] = props.emptyOptionText,
                    _a);
                // 초성 검색이나 일반 검색에 걸리면 빈 값도 포함
                if (isInitialSearch && emptyOptionInitials.startsWith(lowerCaseQuery) ||
                    props.emptyOptionText.toLowerCase().includes(lowerCaseQuery)) {
                    return __spreadArray([emptyOption], filtered, true);
                }
                else {
                    return filtered;
                }
            }
            else {
                return filtered;
            }
        });
        var validateSelection = function () {
            if (props.displayEmptyOption) {
                isInvalid.value = false;
            }
            else {
                isInvalid.value = !selected.value;
            }
        };
        // 드롭다운 외부 클릭 시 닫기 처리
        var handleClickOutside = function (event) {
            var dropdownElement = dropdownRef.value;
            if (dropdownElement && dropdownElement.contains(event.target)) {
                return;
            }
            isOpen.value = false;
        };
        // 전역 클릭 이벤트 등록 및 해제
        onMounted(function () {
            window.addEventListener('click', handleClickOutside);
        });
        onBeforeUnmount(function () {
            window.removeEventListener('click', handleClickOutside);
        });
        // 한글 초성 추출 함수
        var extractInitials = function (str) {
            var initialConsonants = [
                'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
            ];
            return str.split('').map(function (char) {
                var code = char.charCodeAt(0) - 44032;
                // 한글이 아닌 경우 그대로 반환
                if (code < 0 || code > 11171) {
                    return char;
                }
                return initialConsonants[Math.floor(code / 588)];
            }).join('');
        };
        var isKoreanInitial = function (str) {
            return /^[ㄱ-ㅎ]+$/.test(str); // 초성만 있는지 확인
        };
        window.validateSelection = validateSelection;
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", {
                class: "dropdown",
                ref_key: "dropdownRef",
                ref: dropdownRef
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _withDirectives(_createElementVNode("input", {
                        type: "hidden",
                        name: "".concat(props.type),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (_isRef(selected) ? (selected).value = $event : selected = $event); }),
                        required: ""
                    }, null, 8, _hoisted_2), [
                        [_vModelText, _unref(selected)]
                    ]),
                    _createElementVNode("input", {
                        id: "".concat(props.type),
                        class: _normalizeClass(["dropdown_toggle", { 'is-open': isOpen.value }]),
                        onClick: toggleDropdown,
                        value: _unref(selectedLabel),
                        placeholder: __props.hintText,
                        readonly: ""
                    }, null, 10, _hoisted_3),
                    _createElementVNode("label", {
                        for: "".concat(props.type)
                    }, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "bi bi-chevron-down" }, null, -1)
                    ]), 8, _hoisted_4)
                ]),
                (isOpen.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("input", {
                                ref_key: "searchInputRef",
                                ref: searchInputRef,
                                type: "search",
                                onKeyup: onKeyup,
                                onKeydown: onKeydown,
                                onCompositionstart: _cache[1] || (_cache[1] = function ($event) { return (isComposing.value = true); }),
                                onCompositionend: handleCompositionEnd,
                                placeholder: __props.searchHint
                            }, null, 40, _hoisted_7),
                            _withDirectives(_createElementVNode("input", {
                                type: "hidden",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((searchQuery).value = $event); })
                            }, null, 512), [
                                [_vModelText, searchQuery.value]
                            ]),
                            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-search" }, null, -1))
                        ]),
                        _createElementVNode("div", {
                            class: "dropdown_item_group",
                            ref_key: "dropdownMenu",
                            ref: dropdownMenu
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptionsWithEmpty.value, function (code, index) {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: code.code || 'empty',
                                    class: _normalizeClass([{
                                            'selected_item': code.code === _unref(selected),
                                            'active_item': index === activeIndex.value
                                        }, "dropdown_item"]),
                                    onClick: function () { return selectOption(code); },
                                    "data-index": index
                                }, [
                                    _createElementVNode("span", null, _toDisplayString(code["lang_".concat(_unref(lang))] || __props.emptyOptionText), 1),
                                    (code.code === _unref(selected))
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                                        : _createCommentVNode("", true)
                                ], 10, _hoisted_8));
                            }), 128))
                        ], 512)
                    ]))
                    : _createCommentVNode("", true),
                (isInvalid.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, "＊ " + _toDisplayString(__props.errText), 1))
                    : _createCommentVNode("", true)
            ], 512));
        };
    } }));
