var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
    key: 0,
    class: "recruit field recruit-field"
};
var _hoisted_2 = { class: "recruit-field-title field_title d-flex align-items-center" };
var _hoisted_3 = { class: "total" };
var _hoisted_4 = { class: "company_recruit" };
var _hoisted_5 = { class: "recruit-list-row d-flex" };
var _hoisted_6 = { class: "content_group" };
var _hoisted_7 = { class: "recruit-list d-flex flex-wrap" };
var _hoisted_8 = ["href"];
var _hoisted_9 = { class: "d-flex align-items-start text-decoration-none" };
var _hoisted_10 = { class: "item_box d-flex flex-column flex-fill" };
var _hoisted_11 = { class: "top_line flex-fill d-flex align-items-center" };
var _hoisted_12 = {
    key: 0,
    class: "text-info"
};
var _hoisted_13 = {
    key: 1,
    class: "text-info"
};
var _hoisted_14 = ["title"];
var _hoisted_15 = { class: "recruit_title d-flex align-items-center" };
var _hoisted_16 = { class: "title" };
var _hoisted_17 = { class: "tags d-flex flex-wrap" };
var _hoisted_18 = ["title"];
var _hoisted_19 = ["title"];
var _hoisted_20 = ["title"];
var _hoisted_21 = ["title"];
var _hoisted_22 = ["title"];
var _hoisted_23 = ["title"];
var _hoisted_24 = { key: 0 };
var _hoisted_25 = { class: "img_box text-center" };
var _hoisted_26 = {
    key: 0,
    class: "text-center mt-3.5"
};
var _hoisted_27 = {
    key: 1,
    class: "recruit_all d-flex justify-content-center"
};
var _hoisted_28 = { type: "button" };
var _hoisted_29 = ["href"];
import ApiService from '@/ts/core/services/ApiService';
import { startSpinner } from '@/ts/modules/startSpinner';
import { Tooltip } from "bootstrap";
import { ref, onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RecruitList',
    props: {
        recruitMgmtCode: {
            required: false,
            type: String,
        },
        companyMgmtCode: {
            required: false,
            type: String,
        },
        urlPath: {
            required: true,
            type: String,
        },
        pagesize: {
            required: false,
            type: Number,
            default: 10,
        },
        moreBtn: {
            required: false,
            type: Boolean,
            default: true,
        },
        title: {
            required: false,
            type: String,
            default: '',
        },
    },
    setup: function (__props) {
        var _this = this;
        var langHeader = document.head.querySelector('meta[name="App-Language"]');
        var lang = langHeader.content;
        var initTooltip = function () {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        };
        onMounted(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, loadLocale()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, loadList()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        var props = __props;
        var recruits = ref([]); // 채용 공고 리스트
        var currentPage = ref(1); // 현재 페이지
        var total = ref(0); // 총 데이터 개수
        var lastPage = ref(0);
        var locales = ref({});
        var loadLocale = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, ApiService.get("".concat(process.env.MIX_API_HOST, "/trans/service"))];
                    case 1:
                        data = (_a.sent()).data;
                        if (data) {
                            locales.value = data.data;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error loading locales:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var loadList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, spinnerContainer, spinner;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = startSpinner(), spinnerContainer = _a.spinnerContainer, spinner = _a.spinner;
                        return [4 /*yield*/, ApiService.query("".concat(process.env.MIX_API_HOST).concat(props.urlPath), {
                                params: {
                                    pagesize: props.pagesize,
                                    page: currentPage.value,
                                    excludes: [props.recruitMgmtCode],
                                }
                            })
                                .then(function (_a) {
                                var data = _a.data;
                                console.log(data.data);
                                var pagingData = data.data;
                                var list = pagingData.data;
                                list.forEach(function (recruit) {
                                    recruit.primary_text_meta = recruit.text_metas.find(function (meta) { return meta.lang == lang; }) || recruit.text_metas[0];
                                    recruits.value.push(recruit);
                                });
                                console.log(recruits.value);
                                total.value = data.data.total;
                                lastPage.value = data.data.last_page;
                            })
                                .catch(function (_a) {
                                var response = _a.response;
                                if (response) {
                                    console.warn(response);
                                }
                            })
                                .finally(function () {
                                initTooltip();
                                if (spinnerContainer) {
                                    spinnerContainer.style.display = 'none';
                                }
                                spinner.stop();
                            })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        }); };
        var loadMore = function () {
            if (recruits.value.length >= total.value)
                return; // 모든 데이터 로드 완료 시 종료
            currentPage.value += 1; // 다음 페이지 요청
            console.log(currentPage.value);
            loadList(); // 데이터 불러오기 실행
        };
        var filterCodes = function (recruit, type) {
            return recruit.codes.filter(function (code) { return code.parent_code.startsWith(type); });
        };
        var generateLocationTooltip = function (recruit) {
            return filterCodes(recruit, 'location')
                .map(function (code) { var _a; return "".concat((_a = code.parent) === null || _a === void 0 ? void 0 : _a["lang_".concat(lang)], " ").concat(code["lang_".concat(lang)]); })
                .join(', ');
        };
        var generateTaskTooltip = function (recruit) {
            return filterCodes(recruit, 'task')
                .map(function (code) { return "".concat(code["lang_".concat(lang)]); })
                .join(', ');
        };
        return function (_ctx, _cache) {
            return (total.value > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("p", _hoisted_2, [
                        _createTextVNode(_toDisplayString(props.title) + " ", 1),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(total.value), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recruits.value, function (recruit) {
                                        var _a, _b;
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: recruit.id,
                                            class: "item"
                                        }, [
                                            _createElementVNode("a", {
                                                href: "/".concat(_unref(lang), "/recruit/").concat(recruit.mgmt_code),
                                                class: "item_link d-flex flex-column"
                                            }, [
                                                _createElementVNode("div", _hoisted_9, [
                                                    _createElementVNode("div", _hoisted_10, [
                                                        _createElementVNode("div", _hoisted_11, [
                                                            (recruit.common_text_metas.meta.company_name)
                                                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(recruit.common_text_metas.meta.company_name), 1))
                                                                : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(locales.value.recruit_company_jobploy), 1)),
                                                            (recruit.common_text_metas.meta.is_certified)
                                                                ? (_openBlock(), _createElementBlock("div", {
                                                                    key: 2,
                                                                    class: "check_mark_area",
                                                                    "data-bs-toggle": "tooltip",
                                                                    "data-bs-placement": "bottom",
                                                                    title: locales.value.company_verifydesc
                                                                }, _cache[0] || (_cache[0] = [
                                                                    _createElementVNode("object", {
                                                                        type: "image/svg+xml",
                                                                        data: "/images/icons/check_mark.svg"
                                                                    }, null, -1)
                                                                ]), 8, _hoisted_14))
                                                                : _createCommentVNode("", true)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_15, [
                                                            _createElementVNode("div", _hoisted_16, _toDisplayString(recruit.primary_text_meta.meta.title), 1)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_17, [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterCodes(recruit, 'special_visasp'), function (code) {
                                                                return (_openBlock(), _createElementBlock("span", {
                                                                    key: code.code,
                                                                    "data-bs-toggle": "tooltip",
                                                                    "data-bs-placement": "bottom",
                                                                    title: code["lang_".concat(_unref(lang))],
                                                                    class: "tag tag_blue"
                                                                }, _toDisplayString(code["lang_".concat(_unref(lang))]), 9, _hoisted_18));
                                                            }), 128)),
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterCodes(recruit, 'work_paytype'), function (code) {
                                                                var _a, _b;
                                                                return (_openBlock(), _createElementBlock(_Fragment, {
                                                                    key: code.code
                                                                }, [
                                                                    (code.code !== 'work_paytype_00')
                                                                        ? (_openBlock(), _createElementBlock("span", {
                                                                            key: 0,
                                                                            "data-bs-toggle": "tooltip",
                                                                            "data-bs-placement": "bottom",
                                                                            title: "".concat(code['lang_' + _unref(lang)], " : ").concat((_a = recruit.common_text_metas.meta.work_payment) === null || _a === void 0 ? void 0 : _a.toLocaleString(), " ").concat(locales.value.common_krw),
                                                                            class: "tag"
                                                                        }, _toDisplayString(code["lang_".concat(_unref(lang))]) + " : " + _toDisplayString((_b = recruit.common_text_metas.meta.work_payment) === null || _b === void 0 ? void 0 : _b.toLocaleString()) + " " + _toDisplayString(locales.value.common_krw), 9, _hoisted_19))
                                                                        : (_openBlock(), _createElementBlock("span", {
                                                                            key: 1,
                                                                            "data-bs-toggle": "tooltip",
                                                                            "data-bs-placement": "bottom",
                                                                            title: code["lang_".concat(_unref(lang))],
                                                                            class: "tag"
                                                                        }, _toDisplayString(code["lang_".concat(_unref(lang))]), 9, _hoisted_20))
                                                                ], 64));
                                                            }), 128)),
                                                            (filterCodes(recruit, 'location').length > 0)
                                                                ? (_openBlock(), _createElementBlock("span", {
                                                                    key: 0,
                                                                    "data-bs-toggle": "tooltip",
                                                                    "data-bs-placement": "bottom",
                                                                    title: generateLocationTooltip(recruit),
                                                                    class: "tag"
                                                                }, [
                                                                    _createTextVNode(_toDisplayString((_a = filterCodes(recruit, 'location')[0].parent) === null || _a === void 0 ? void 0 : _a["lang_".concat(_unref(lang))]) + " " + _toDisplayString(filterCodes(recruit, 'location')[0]["lang_".concat(_unref(lang))]) + " ", 1),
                                                                    (filterCodes(recruit, 'location').length > 1)
                                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                            _createTextVNode(_toDisplayString(locales.value.common_and) + " + " + _toDisplayString(filterCodes(recruit, 'location').length - 1), 1)
                                                                        ], 64))
                                                                        : _createCommentVNode("", true)
                                                                ], 8, _hoisted_21))
                                                                : _createCommentVNode("", true),
                                                            (filterCodes(recruit, 'task').length > 0)
                                                                ? (_openBlock(), _createElementBlock("span", {
                                                                    key: 1,
                                                                    "data-bs-toggle": "tooltip",
                                                                    "data-bs-placement": "bottom",
                                                                    title: generateTaskTooltip(recruit),
                                                                    class: "tag"
                                                                }, [
                                                                    _createTextVNode(_toDisplayString(filterCodes(recruit, 'task')[0]["lang_".concat(_unref(lang))]) + " ", 1),
                                                                    (filterCodes(recruit, 'task').length > 1)
                                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                            _createTextVNode(_toDisplayString(locales.value.common_and) + " + " + _toDisplayString(filterCodes(recruit, 'task').length - 1), 1)
                                                                        ], 64))
                                                                        : _createCommentVNode("", true)
                                                                ], 8, _hoisted_22))
                                                                : _createCommentVNode("", true),
                                                            (recruit.common_text_metas.meta.use_deadline)
                                                                ? (_openBlock(), _createElementBlock("span", {
                                                                    key: 2,
                                                                    "data-bs-toggle": "tooltip",
                                                                    "data-bs-placement": "bottom",
                                                                    title: recruit.common_text_metas.meta.closed_at
                                                                        ? (new Date(recruit.common_text_metas.meta.closed_at).getTime() - Date.now() < 0
                                                                            ? locales.value.recruit_duedate
                                                                            : "".concat(locales.value.recruit_duedate, " D-").concat(Math.ceil((new Date(recruit.common_text_metas.meta.closed_at).getTime() - Date.now()) / (1000 * 60 * 60 * 24))))
                                                                        : locales.value.recruit_duedate,
                                                                    class: "tag"
                                                                }, _toDisplayString(recruit.common_text_metas.meta.closed_at
                                                                    ? (new Date(recruit.common_text_metas.meta.closed_at).getTime() - Date.now() < 0
                                                                        ? locales.value.recruit_duedate
                                                                        : "".concat(locales.value.recruit_duedate, " D-").concat(Math.ceil((new Date(recruit.common_text_metas.meta.closed_at).getTime() - Date.now()) / (1000 * 60 * 60 * 24))))
                                                                    : locales.value.recruit_duedate), 9, _hoisted_23))
                                                                : _createCommentVNode("", true)
                                                        ])
                                                    ]),
                                                    (recruit.file_metas.length > 0)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                                            _createElementVNode("div", _hoisted_25, [
                                                                _createElementVNode("div", {
                                                                    class: "square border border-secondary-100",
                                                                    style: _normalizeStyle({ background: "url(".concat((_b = recruit.file_metas[0]) === null || _b === void 0 ? void 0 : _b.url, ") no-repeat"), backgroundSize: 'cover', backgroundPosition: 'center' })
                                                                }, null, 4)
                                                            ])
                                                        ]))
                                                        : _createCommentVNode("", true)
                                                ])
                                            ], 8, _hoisted_8)
                                        ]));
                                    }), 128))
                                ]),
                                (lastPage.value > currentPage.value && props.moreBtn)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                        _createElementVNode("button", {
                                            onClick: loadMore,
                                            class: "more_btn white_btn"
                                        }, [
                                            _createTextVNode(_toDisplayString(locales.value.common_10more), 1),
                                            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "bi bi-chevron-right" }, null, -1))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                (lastPage.value > currentPage.value && !props.moreBtn)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                        _createElementVNode("button", _hoisted_28, [
                                            _createElementVNode("a", {
                                                href: "/".concat(_unref(lang), "/company/").concat(props.companyMgmtCode, "/recruit")
                                            }, [
                                                _createTextVNode(_toDisplayString(locales.value.company_more) + " ", 1),
                                                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-chevron-right" }, null, -1))
                                            ], 8, _hoisted_29)
                                        ])
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
