import Spinner from 'spin.js';
export function startSpinner() {
    var spinner = new Spinner({
        lines: 12,
        length: 7,
        width: 5,
        radius: 10,
        scale: 1.5,
        corners: 1,
        color: '#3498db',
        fadeColor: 'transparent',
        speed: 1,
        rotate: 0,
        animation: 'spinner-line-fade-default',
        direction: 1,
        zIndex: 2e9,
        className: 'spinner',
        top: '50%',
        left: '50%',
        shadow: '0 0 1px transparent',
        position: 'fixed'
    });
    var spinnerContainer = document.getElementById('spinnerContainer');
    if (spinnerContainer) {
        spinnerContainer.style.display = 'block';
    }
    spinner.spin(document.body);
    var stopSpinner = function (spinnerInstance) {
        spinner.stop();
        spinnerInstance.style.display = 'none';
    };
    return { spinner: spinner, spinnerContainer: spinnerContainer, stopSpinner: stopSpinner };
}
