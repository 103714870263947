import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow, createStaticVNode as _createStaticVNode } from "vue";
var _hoisted_1 = { class: "position-relative" };
var _hoisted_2 = { class: "filter_title_group" };
var _hoisted_3 = {
    class: "title",
    id: "basic-addon1"
};
var _hoisted_4 = { class: "desc" };
var _hoisted_5 = { class: "select_box" };
var _hoisted_6 = { class: "text-truncate fs-6.5 d-flex flex-wrap" };
var _hoisted_7 = {
    key: 0,
    class: "hint"
};
var _hoisted_8 = { key: 0 };
var _hoisted_9 = ["onClick"];
var _hoisted_10 = { class: "filterUI" };
var _hoisted_11 = { class: "row filterUI-body" };
var _hoisted_12 = { class: "filterUI-body-right col h-100" };
var _hoisted_13 = { class: "row filterUI-body-list" };
var _hoisted_14 = { class: "form-check" };
var _hoisted_15 = ["id", "value", "onChange"];
var _hoisted_16 = ["for"];
var _hoisted_17 = { class: "row filterUI-footer" };
var _hoisted_18 = { class: "result d-flex flex-wrap" };
var _hoisted_19 = ["value"];
var _hoisted_20 = { class: "text-info mx-3" };
var _hoisted_21 = ["onClick"];
var _hoisted_22 = ["value"];
var _hoisted_23 = { class: "text-info mx-3" };
var _hoisted_24 = ["onClick"];
var _hoisted_25 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_26 = { class: "d-flex align-items-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.locales.interest_visa), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
                class: "dropdown-toggle position-relative",
                onClick: _cache[0] || (_cache[0] = function ($event) { return (_ctx.toggleFilterPopup()); })
            }, [
                _createElementVNode("div", _hoisted_6, [
                    (_ctx.selectedCodes.length == 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.locales.common_visahint), 1))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.selectedCodes, function (code) {
                            return (_openBlock(), _createElementBlock("div", {
                                key: 'title_' + code.code,
                                class: "selected_code"
                            }, [
                                (_ctx.menus.indexOf(code.code) < 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(code.parent ? code.parent['lang_' + _ctx.lang] : '') + " > " + _toDisplayString(code['lang_' + _ctx.lang]), 1))
                                    : _createCommentVNode("", true),
                                _createElementVNode("button", {
                                    class: "btn btn-sm",
                                    type: "button",
                                    "aria-label": "Close",
                                    onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                }, _cache[4] || (_cache[4] = [
                                    _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                ]), 8, _hoisted_9)
                            ]));
                        }), 128))
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("button", { type: "button" }, [
                    _createElementVNode("i", { class: "bi bi-chevron-down" })
                ], -1))
            ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_10, [
            _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"filterUI-search\"><div class=\"input-group\"><span class=\"input-group-text border-end-0 bg-white\"><i class=\"bi bi-search\"></i></span><div style=\"flex:1 1 auto;\"><select type=\"search\" class=\"form-select border-start-0\" placeholder=\"직무 명칭을 검색해보세요.\" aria-describedby=\"basic-addon1\"></select></div></div></div>", 1)),
            _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_a = _ctx.selectedCategory) === null || _a === void 0 ? void 0 : _a.children, function (code) {
                            return (_openBlock(), _createElementBlock("div", {
                                class: "filterUI-body-list-item",
                                key: code.code
                            }, [
                                _createElementVNode("div", _hoisted_14, [
                                    _withDirectives(_createElementVNode("input", {
                                        class: "form-check-input",
                                        id: code.code,
                                        type: "checkbox",
                                        value: code,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((_ctx.selectedCodes) = $event); }),
                                        onChange: function ($event) { return (_ctx.selectCode($event, code)); }
                                    }, null, 40, _hoisted_15), [
                                        [_vModelCheckbox, _ctx.selectedCodes]
                                    ]),
                                    _createElementVNode("label", {
                                        class: "form-check-label",
                                        for: code.code
                                    }, _toDisplayString(code['lang_' + _ctx.lang]), 9, _hoisted_16)
                                ])
                            ]));
                        }), 128))
                    ])
                ])
            ]),
            _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCodes, function (code) {
                        return (_openBlock(), _createElementBlock("div", {
                            key: 'selected_' + code.code,
                            class: "d-flex align-items-center"
                        }, [
                            (_ctx.menus.indexOf(code.code) < 0)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("input", {
                                        type: "hidden",
                                        name: "etc_filter[]",
                                        value: code.code
                                    }, null, 8, _hoisted_19),
                                    _createElementVNode("span", _hoisted_20, _toDisplayString(code.parent ? code.parent['lang_' + _ctx.lang] : '') + " > " + _toDisplayString(code['lang_' + _ctx.lang]), 1),
                                    _createElementVNode("button", {
                                        class: "btn-close btn btn-sm",
                                        type: "button",
                                        "aria-label": "Close",
                                        onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                    }, _cache[6] || (_cache[6] = [
                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                    ]), 8, _hoisted_21)
                                ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createElementVNode("input", {
                                        type: "hidden",
                                        name: "etc_category_filter[]",
                                        value: code.code
                                    }, null, 8, _hoisted_22),
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(code['lang_' + _ctx.lang]) + " > " + _toDisplayString(_ctx.locales.common_all), 1),
                                    _createElementVNode("button", {
                                        class: "btn-close btn btn-sm",
                                        type: "button",
                                        "aria-label": "Close",
                                        onClick: function ($event) { return (_ctx.removeSelectedCode(code, $event)); }
                                    }, _cache[7] || (_cache[7] = [
                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                    ]), 8, _hoisted_24)
                                ], 64))
                        ]));
                    }), 128))
                ]),
                _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("button", {
                            class: "btn btn-sm py-0",
                            type: "button",
                            onClick: _cache[2] || (_cache[2] = function ($event) { return (_ctx.resetFilter()); })
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.locales.common_reset), 1),
                            _cache[8] || (_cache[8] = _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1))
                        ])
                    ]),
                    _createElementVNode("button", {
                        onClick: _cache[3] || (_cache[3] = function ($event) { return (_ctx.closeFilterPopup()); }),
                        class: "btn btn-primary text-white",
                        type: "button"
                    }, _toDisplayString(_ctx.locales.common_set), 1)
                ])
            ])
        ], 512), [
            [_vShow, _ctx.filterVisible]
        ])
    ]));
}
