import axios from "axios";
/**
 * @description service to call HTTP request via Axios
 * Manager 페이지의 ApiService를 커스텀
 */
var ApiService = /** @class */ (function () {
    /**
     * @description initialize vue axios
     */
    function ApiService() {
        /**
         * @description property to share vue instance
         */
        this.axiosInstans = axios;
        this.setHeader();
    }
    /**
     * @description set the default HTTP request headers
     */
    ApiService.prototype.setHeader = function () {
        var token = this.getToken();
        this.axiosInstans.defaults.headers.common["Authorization"] = "Bearer ".concat(token);
        this.axiosInstans.defaults.headers.common["Accept"] =
            "application/json";
        this.axiosInstans.defaults.headers.common['App-Language'] = this.getLang();
    };
    ApiService.prototype.getLang = function () {
        var app_language = document.head.querySelector('meta[name="App-Language"]');
        if (app_language) {
            console.log("app_language", app_language.content);
            return app_language.content;
        }
        else {
            console.warn('app language header can not found');
            return 'ko';
        }
    };
    ApiService.prototype.getToken = function () {
        var headerToken = document.head.querySelector('meta[name="App-Access-Token"]');
        return headerToken.content;
    };
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.query = function (resource, params) {
        return this.axiosInstans.get(resource, params);
    };
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.get = function (resource, slug) {
        if (slug === void 0) { slug = ""; }
        return this.axiosInstans.get("".concat(resource, "/").concat(slug));
    };
    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.post = function (resource, params) {
        return this.axiosInstans.post("".concat(resource), params);
    };
    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.update = function (resource, slug, params) {
        return this.axiosInstans.put("".concat(resource, "/").concat(slug), params);
    };
    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.put = function (resource, params) {
        return this.axiosInstans.put("".concat(resource), params);
    };
    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    ApiService.prototype.delete = function (resource) {
        return this.axiosInstans.delete(resource);
    };
    return ApiService;
}());
export default new ApiService;
