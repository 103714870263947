var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = ["name", "value", "id"];
var _hoisted_2 = ["for"];
import { ref, watch } from 'vue';
var __default__ = {
    name: 'resume-checkbox',
};
export default /*@__PURE__*/ _defineComponent(__assign(__assign({}, __default__), { props: {
        type: {
            required: true,
            type: String,
        },
        initValue: {
            required: false,
            type: (Array)
        },
        options: {
            required: true,
            type: (Array)
        }
    }, setup: function (__props) {
        var lang = document.documentElement.lang;
        var selected = ref(([]));
        var props = __props;
        watch(function () { return props.initValue; }, function (newVal) {
            selected.value = newVal;
        }, { deep: true });
        return function (_ctx, _cache) {
            return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, function (code) {
                return (_openBlock(), _createElementBlock("div", {
                    key: code.code,
                    class: "d-flex align-items-center"
                }, [
                    _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        name: "".concat(props.type, "[]"),
                        value: code.code,
                        id: code.code,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return (_isRef(selected) ? (selected).value = $event : selected = $event); })
                    }, null, 8, _hoisted_1), [
                        [_vModelCheckbox, _unref(selected)]
                    ]),
                    _createElementVNode("label", {
                        for: code.code
                    }, _toDisplayString(code["lang_".concat(_unref(lang))]), 9, _hoisted_2)
                ]));
            }), 128));
        };
    } }));
