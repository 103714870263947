import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow } from "vue";
var _hoisted_1 = { class: "select_box" };
var _hoisted_2 = { class: "text-truncate d-flex flex-wrap fs-6.5" };
var _hoisted_3 = {
    key: 0,
    class: "hint",
    style: { "font-size": "0.9rem" }
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = ["id"];
var _hoisted_6 = { class: "filter_search" };
var _hoisted_7 = { class: "row filterUI-body" };
var _hoisted_8 = { class: "filterUI-body-right col h-100" };
var _hoisted_9 = { class: "row filterUI-body-list" };
var _hoisted_10 = { class: "form-check" };
var _hoisted_11 = ["id", "value"];
var _hoisted_12 = ["for"];
var _hoisted_13 = { class: "row filterUI-footer" };
var _hoisted_14 = { class: "result d-flex flex-wrap" };
var _hoisted_15 = ["name", "value"];
var _hoisted_16 = { class: "text-info mx-3" };
var _hoisted_17 = ["onClick"];
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
// Props
export default /*@__PURE__*/ _defineComponent({
    __name: 'CodeMetaFilter',
    props: {
        type: {
            required: true,
            type: String,
        },
        options: {
            required: true,
            type: (Array),
            default: [],
        },
        activeFilter: {
            type: String,
            default: null
        },
    },
    emits: ['update-checked-code', 'update-active-filter'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        // Reactive 데이터
        var lang = ref(document.documentElement.lang); // 현재 언어
        var filterVisible = ref(false); // 필터 UI 표시 여부
        var selectedCategory = ref([]);
        var searchInputRef = ref(null);
        var searchQuery = ref(''); // 검색어
        var isComposing = ref(false);
        var filterContainer = ref(null);
        var emit = __emit;
        var previousSearchQuery = "";
        var onKeyup = function (event) {
            var currentQuery = event.target.value;
            // 검색어가 변경되었는지 확인
            if (currentQuery !== previousSearchQuery) {
                previousSearchQuery = currentQuery;
            }
            searchQuery.value = currentQuery;
        };
        var currentLang = computed(function () { return lang.value; });
        var filteredOptions = computed(function () {
            var _a;
            var lowerCaseQuery = ((_a = searchQuery.value) === null || _a === void 0 ? void 0 : _a.toLowerCase().trim()) || '';
            var isInitialSearch = isKoreanInitial(lowerCaseQuery);
            return props.options.filter(function (option) {
                var _a;
                var optionLabel = ((_a = option["lang_".concat(currentLang.value)]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
                var optionInitials = extractInitials(optionLabel);
                if (isInitialSearch) {
                    return optionInitials.startsWith(lowerCaseQuery);
                }
                else {
                    return optionLabel.includes(lowerCaseQuery);
                }
            });
        });
        watch(function () { return props.activeFilter; }, function (newVal) {
            filterVisible.value = newVal === props.type;
        });
        watch(selectedCategory, function (newSelectedCategory) {
            emit('update-checked-code', props.type, newSelectedCategory);
        }, { deep: true });
        // 한글 입력 완료 시 발생
        var handleCompositionEnd = function (event) {
            isComposing.value = false;
            searchQuery.value = event.target.value;
        };
        // Methods
        var toggleFilterPopup = function () {
            emit('update-active-filter', props.type);
        };
        var resetFilter = function () {
            selectedCategory.value = [];
            searchQuery.value = ''; // 검색어 초기화
        };
        var removeSelectedCategory = function (code) {
            selectedCategory.value = selectedCategory.value.filter(function (selected) { return selected.code !== code.code; });
        };
        // 외부 클릭 감지 로직
        var handleOutsideClick = function (event) {
            if (filterContainer.value && !filterContainer.value.contains(event.target)) {
                filterVisible.value = false;
            }
        };
        // 마운트 및 언마운트 시 이벤트 등록 및 제거
        onMounted(function () {
            document.addEventListener('click', handleOutsideClick);
        });
        onBeforeUnmount(function () {
            document.removeEventListener('click', handleOutsideClick);
        });
        // 한글 초성 추출 함수
        var extractInitials = function (str) {
            var initialConsonants = [
                'ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ', 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'
            ];
            return str.split('').map(function (char) {
                var code = char.charCodeAt(0) - 44032;
                // 한글이 아닌 경우 그대로 반환
                if (code < 0 || code > 11171) {
                    return char;
                }
                return initialConsonants[Math.floor(code / 588)];
            }).join('');
        };
        var isKoreanInitial = function (str) {
            return /^[ㄱ-ㅎ]+$/.test(str); // 초성만 있는지 확인
        };
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", {
                class: "d-flex flex-column w-100 position-relative",
                ref_key: "filterContainer",
                ref: filterContainer
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: "dropdown-toggle position-relative",
                        onClick: _withModifiers(toggleFilterPopup, ["stop"])
                    }, [
                        _createElementVNode("div", _hoisted_2, [
                            (selectedCategory.value.length === 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.type === 'info_country' ? '우대국가를 선택해주세요' :
                                    props.type === 'con_language' ? '언어능력을 선택해주세요' : ''), 1))
                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedCategory.value, function (code) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: 'title_' + code.code,
                                        class: "selected_code"
                                    }, [
                                        _createElementVNode("span", null, _toDisplayString(code['lang_' + lang.value]), 1),
                                        _createElementVNode("button", {
                                            class: "btn btn-sm",
                                            type: "button",
                                            "aria-label": "Close",
                                            onClick: _withModifiers(function ($event) { return (removeSelectedCategory(code)); }, ["stop"])
                                        }, _cache[3] || (_cache[3] = [
                                            _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                        ]), 8, _hoisted_4)
                                    ]));
                                }), 128))
                        ]),
                        _cache[4] || (_cache[4] = _createElementVNode("button", { type: "button" }, [
                            _createElementVNode("i", { class: "bi bi-chevron-down" })
                        ], -1))
                    ])
                ]),
                _withDirectives(_createElementVNode("div", {
                    class: "filterUI",
                    id: __props.type + 'Filter'
                }, [
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("input", {
                            ref_key: "searchInputRef",
                            ref: searchInputRef,
                            type: "search",
                            onKeyup: onKeyup,
                            onCompositionstart: _cache[0] || (_cache[0] = function ($event) { return (isComposing.value = true); }),
                            onCompositionend: handleCompositionEnd,
                            placeholder: "검색해주세요."
                        }, null, 544),
                        _withDirectives(_createElementVNode("input", {
                            type: "hidden",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((searchQuery).value = $event); })
                        }, null, 512), [
                            [_vModelText, searchQuery.value]
                        ]),
                        _cache[5] || (_cache[5] = _createElementVNode("i", { class: "bi bi-search" }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOptions.value, function (category) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        class: "filterUI-body-list-item",
                                        key: category.code
                                    }, [
                                        _createElementVNode("div", _hoisted_10, [
                                            _withDirectives(_createElementVNode("input", {
                                                class: "form-check-input",
                                                id: category.code,
                                                type: "checkbox",
                                                value: category,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((selectedCategory).value = $event); })
                                            }, null, 8, _hoisted_11), [
                                                [_vModelCheckbox, selectedCategory.value]
                                            ]),
                                            _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: category.code
                                            }, _toDisplayString(category['lang_' + lang.value]), 9, _hoisted_12)
                                        ])
                                    ]));
                                }), 128))
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("input", {
                                    key: 'hidden_' + code.code,
                                    type: "hidden",
                                    name: "".concat(__props.type, "[]"),
                                    value: code.code
                                }, null, 8, _hoisted_15));
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: 'selected_' + code.code,
                                    class: "d-flex align-items-center"
                                }, [
                                    (selectedCategory.value.length > 0)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("span", _hoisted_16, _toDisplayString(code['lang_' + lang.value]), 1),
                                            _createElementVNode("button", {
                                                class: "btn-close btn btn-sm",
                                                type: "button",
                                                "aria-label": "Close",
                                                onClick: function ($event) { return (removeSelectedCategory(code)); }
                                            }, _cache[6] || (_cache[6] = [
                                                _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                            ]), 8, _hoisted_17)
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ]),
                        _createElementVNode("div", { class: "btns d-flex justify-content-end px-4 py-3" }, [
                            _createElementVNode("div", { class: "d-flex align-items-end" }, [
                                _createElementVNode("button", {
                                    class: "btn btn-sm py-0",
                                    type: "button",
                                    onClick: resetFilter
                                }, _cache[7] || (_cache[7] = [
                                    _createTextVNode(" 초기화"),
                                    _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1)
                                ]))
                            ]),
                            _createElementVNode("button", {
                                onClick: toggleFilterPopup,
                                class: "btn btn-primary text-white",
                                type: "button"
                            }, " 적용 ")
                        ])
                    ])
                ], 8, _hoisted_5), [
                    [_vShow, filterVisible.value]
                ])
            ], 512));
        };
    }
});
