import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = { class: "create_page" };
var _hoisted_2 = { class: "d-flex flex-column align-items-center justify-content-center" };
var _hoisted_3 = { class: "card" };
var _hoisted_4 = { class: "card-body d-flex flex-column" };
var _hoisted_5 = { class: "d-flex" };
var _hoisted_6 = { class: "d-flex" };
var _hoisted_7 = {
    class: "editor",
    ref: "descRef"
};
var _hoisted_8 = { class: "d-flex" };
var _hoisted_9 = { class: "input-group" };
var _hoisted_10 = { class: "input-group-text" };
var _hoisted_11 = { class: "form-check" };
var _hoisted_12 = ["disabled"];
var _hoisted_13 = ["disabled", "readonly"];
var _hoisted_14 = { class: "card" };
var _hoisted_15 = { class: "card-body d-flex flex-column" };
var _hoisted_16 = { class: "d-flex" };
var _hoisted_17 = ["disabled"];
var _hoisted_18 = { class: "d-flex" };
var _hoisted_19 = { class: "d-flex flex-column" };
var _hoisted_20 = { class: "input-group" };
var _hoisted_21 = ["disabled"];
var _hoisted_22 = { class: "d-flex" };
var _hoisted_23 = { class: "input-group" };
var _hoisted_24 = { class: "border d-flex align-items-center flex-wrap" };
var _hoisted_25 = {
    key: 0,
    class: "text-info d-flex align-items-center"
};
var _hoisted_26 = { class: "d-flex" };
var _hoisted_27 = { class: "card" };
var _hoisted_28 = { class: "card-body d-flex flex-column" };
var _hoisted_29 = { class: "d-flex" };
var _hoisted_30 = ["disabled"];
var _hoisted_31 = { class: "d-flex" };
var _hoisted_32 = ["disabled"];
var _hoisted_33 = { class: "d-flex" };
var _hoisted_34 = ["disabled"];
var _hoisted_35 = { class: "card" };
var _hoisted_36 = { class: "card-body d-flex flex-column" };
var _hoisted_37 = { class: "d-flex" };
var _hoisted_38 = { class: "input-group" };
var _hoisted_39 = { class: "input-group-text" };
var _hoisted_40 = { class: "form-check" };
var _hoisted_41 = ["disabled"];
var _hoisted_42 = ["disabled", "readonly"];
var _hoisted_43 = { class: "card" };
var _hoisted_44 = {
    class: "collapse",
    id: "collapseOne",
    "aria-labelledby": "headingOne"
};
var _hoisted_45 = { class: "card-body d-flex flex-column" };
var _hoisted_46 = { class: "d-flex" };
var _hoisted_47 = { class: "input-group mb-3" };
var _hoisted_48 = { class: "border d-flex align-items-center flex-wrap" };
var _hoisted_49 = {
    key: 0,
    class: "text-info d-flex align-items-center"
};
var _hoisted_50 = { class: "d-flex" };
var _hoisted_51 = { class: "d-flex" };
var _hoisted_52 = { class: "d-flex" };
var _hoisted_53 = { class: "d-flex" };
var _hoisted_54 = { class: "d-flex" };
var _hoisted_55 = { class: "dropdown" };
var _hoisted_56 = ["disabled"];
var _hoisted_57 = { class: "d-flex" };
var _hoisted_58 = { class: "d-flex" };
var _hoisted_59 = { class: "card" };
var _hoisted_60 = {
    class: "collapse",
    id: "collapseTwo",
    "aria-labelledby": "headingTwo"
};
var _hoisted_61 = { class: "card-body d-flex flex-column" };
var _hoisted_62 = { class: "d-flex" };
var _hoisted_63 = { class: "d-flex" };
var _hoisted_64 = { class: "d-flex" };
var _hoisted_65 = { class: "d-flex" };
var _hoisted_66 = { class: "d-flex" };
var _hoisted_67 = { class: "d-flex" };
var _hoisted_68 = { class: "d-flex" };
var _hoisted_69 = { class: "card" };
var _hoisted_70 = {
    class: "collapse",
    id: "collapseThree",
    "aria-labelledby": "headingThree"
};
var _hoisted_71 = { class: "card-body d-flex flex-column" };
var _hoisted_72 = { class: "d-flex" };
var _hoisted_73 = { class: "d-flex" };
var _hoisted_74 = { class: "d-flex" };
var _hoisted_75 = { class: "d-flex" };
var _hoisted_76 = { class: "d-flex" };
var _hoisted_77 = { class: "d-flex" };
var _hoisted_78 = { class: "d-flex" };
var _hoisted_79 = { class: "d-flex" };
var _hoisted_80 = { class: "card" };
var _hoisted_81 = {
    class: "collapse",
    id: "collapseFour",
    "aria-labelledby": "headingFour"
};
var _hoisted_82 = { class: "card-body d-flex flex-column" };
var _hoisted_83 = { class: "d-flex" };
var _hoisted_84 = { class: "d-flex" };
var _hoisted_85 = { class: "d-flex" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_ServiceCkEditor = _resolveComponent("ServiceCkEditor");
    var _component_SearchAddressModal = _resolveComponent("SearchAddressModal");
    var _component_SelectLocationModal = _resolveComponent("SelectLocationModal");
    var _component_ImageUploadComponent = _resolveComponent("ImageUploadComponent");
    var _component_SelectTaskModal = _resolveComponent("SelectTaskModal");
    var _component_CodeMetaCheckBoxList = _resolveComponent("CodeMetaCheckBoxList");
    var _component_PaytypeInput = _resolveComponent("PaytypeInput");
    var _component_CodeMetaFilter = _resolveComponent("CodeMetaFilter");
    var _component_MobileCodeMetaFilter = _resolveComponent("MobileCodeMetaFilter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _cache[97] || (_cache[97] = _createElementVNode("div", { class: "page_title d-flex align-items-center" }, [
                _createElementVNode("img", {
                    src: "/images/icons/search_logo.svg",
                    alt: ""
                }),
                _createElementVNode("h4", null, "일반 공고 등록")
            ], -1)),
            _createElementVNode("form", {
                class: "form d-flex flex-column align-items-center",
                id: "recruit_create_form",
                onSubmit: _cache[31] || (_cache[31] = _withModifiers(
                //@ts-ignore
                function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return (_ctx.submitRecruit && _ctx.submitRecruit.apply(_ctx, args));
                }, ["prevent"])),
                ref: "formRef"
            }, [
                _cache[94] || (_cache[94] = _createElementVNode("div", { class: "w-100" }, [
                    _createElementVNode("p", { class: "info-text" }, "+ 제목, 내용, 회사명, 근무지 정보와 담당자 연락처 또는 이메일 중 하나만 입력하여도 채용공고가 등록됩니다.")
                ], -1)),
                _createElementVNode("div", _hoisted_3, [
                    _cache[37] || (_cache[37] = _createElementVNode("div", { class: "card-header d-flex flex-column justify-content-center" }, [
                        _createElementVNode("div", { class: "card-title" }, [
                            _createElementVNode("h5", null, "채용내용")
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_4, [
                        _cache[36] || (_cache[36] = _createElementVNode("small", null, "채용내용을 입력해주세요.", -1)),
                        _createElementVNode("div", _hoisted_5, [
                            _cache[32] || (_cache[32] = _createElementVNode("label", {
                                for: "title",
                                class: "col-form-label"
                            }, [
                                _createTextVNode("제목"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "form-control",
                                    "aria-label": "title",
                                    name: "title",
                                    id: "title",
                                    placeholder: "채용공고 타이틀을 입력해주세요",
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((_ctx.recruit.text_metas.meta.title) = $event); }),
                                    ref: "titleRef"
                                }, null, 512), [
                                    [_vModelText, _ctx.recruit.text_metas.meta.title]
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _cache[33] || (_cache[33] = _createElementVNode("label", {
                                for: "desc",
                                class: "col-form-label"
                            }, [
                                _createTextVNode("내용"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_ServiceCkEditor, {
                                    modelValue: _ctx.recruit.text_metas.meta.desc,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((_ctx.recruit.text_metas.meta.desc) = $event); })
                                }, null, 8, ["modelValue"])
                            ], 512)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                            _cache[35] || (_cache[35] = _createElementVNode("label", { class: "col-form-label" }, "마감일", -1)),
                            _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                        _createElementVNode("div", _hoisted_11, [
                                            _withDirectives(_createElementVNode("input", {
                                                type: "checkbox",
                                                class: "form-check-input",
                                                name: "use_deadline",
                                                id: "use_deadline",
                                                value: true,
                                                uncheckedValue: false,
                                                disabled: !_ctx.canEdit(),
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.use_deadline) = $event); })
                                            }, null, 8, _hoisted_12), [
                                                [_vModelCheckbox, _ctx.recruit.common_text_metas.meta.use_deadline]
                                            ]),
                                            _cache[34] || (_cache[34] = _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: "use_deadline"
                                            }, "설정", -1))
                                        ])
                                    ]),
                                    _withDirectives(_createElementVNode("input", {
                                        type: "date",
                                        class: "form-control",
                                        name: "closed_at",
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.closed_at) = $event); }),
                                        disabled: !(_ctx.recruit.common_text_metas.meta.use_deadline && _ctx.canEdit()),
                                        readonly: !(_ctx.recruit.common_text_metas.meta.use_deadline && _ctx.canEdit())
                                    }, null, 8, _hoisted_13), [
                                        [_vModelText, _ctx.recruit.common_text_metas.meta.closed_at]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                    _cache[47] || (_cache[47] = _createElementVNode("div", { class: "card-header d-flex flex-column justify-content-start" }, [
                        _createElementVNode("div", { class: "card-title" }, [
                            _createElementVNode("h5", null, "근무지정보")
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_15, [
                        _cache[46] || (_cache[46] = _createElementVNode("small", null, [
                            _createElementVNode("span", null, "*"),
                            _createTextVNode("상세주소를 입력할 경우 근무지가 자동 세팅되며, 상세주소 입력을 원하지 않을 경우 근무지를 별도로 입력해주세요.")
                        ], -1)),
                        _createElementVNode("div", _hoisted_16, [
                            _cache[38] || (_cache[38] = _createElementVNode("label", {
                                for: "company_name",
                                class: "col-form-label"
                            }, [
                                _createTextVNode("회사명"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "form-control",
                                    "aria-label": "company_name",
                                    name: "company_name",
                                    id: "company_name",
                                    placeholder: "회사명을 입력해주세요",
                                    disabled: !_ctx.canEdit(),
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.company_name) = $event); }),
                                    ref: "companyRef"
                                }, null, 8, _hoisted_17), [
                                    [_vModelText, _ctx.recruit.common_text_metas.meta.company_name]
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            _cache[41] || (_cache[41] = _createElementVNode("label", { class: "col-form-label" }, "회사주소", -1)),
                            _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("div", _hoisted_20, [
                                    _withDirectives(_createElementVNode("button", {
                                        class: "search_btn btn btn-primary",
                                        type: "button",
                                        id: "button-addon1",
                                        onClick: _cache[5] || (_cache[5] = function ($event) { return (_ctx.openModal('addressModalRef')); })
                                    }, _cache[39] || (_cache[39] = [
                                        _createElementVNode("i", { class: "bi bi-search" }, null, -1),
                                        _createTextVNode(" 검색 ")
                                    ]), 512), [
                                        [_vShow, _ctx.canEdit]
                                    ]),
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        placeholder: "",
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.company_address1) = $event); }),
                                        readonly: true,
                                        disabled: true,
                                        name: "address1",
                                        key: "address1"
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.common_text_metas.meta.company_address1]
                                    ]),
                                    _withDirectives(_createElementVNode("button", {
                                        class: "trash_btn btn btn-secondary",
                                        type: "button",
                                        onClick: _cache[7] || (_cache[7] = function ($event) { return (_ctx.clearAddress()); })
                                    }, _cache[40] || (_cache[40] = [
                                        _createElementVNode("i", { class: "bi bi-trash3" }, null, -1)
                                    ]), 512), [
                                        [_vShow, _ctx.canEdit]
                                    ])
                                ]),
                                _createVNode(_component_SearchAddressModal, {
                                    ref: "addressModalRef",
                                    id: "addressModal",
                                    onSelectAddress: _ctx.applyAddress
                                }, null, 8, ["onSelectAddress"]),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        name: "address2",
                                        key: "address2",
                                        placeholder: "나머지 주소를 입력해 주세요",
                                        disabled: !_ctx.canEdit(),
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.company_address2) = $event); })
                                    }, null, 8, _hoisted_21), [
                                        [_vModelText, _ctx.recruit.common_text_metas.meta.company_address2]
                                    ])
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                            _cache[44] || (_cache[44] = _createElementVNode("label", { class: "col-form-label" }, [
                                _createTextVNode("근무지"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_23, [
                                    _withDirectives(_createElementVNode("button", {
                                        class: "search_btn btn btn-primary",
                                        type: "button",
                                        ref: "locationRef",
                                        onClick: _cache[9] || (_cache[9] = function ($event) { return (_ctx.openModal('applySelectedLocationModalRef')); })
                                    }, _cache[42] || (_cache[42] = [
                                        _createElementVNode("i", { class: "bi bi-search" }, null, -1),
                                        _createTextVNode(" 근무지 선택 ")
                                    ]), 512), [
                                        [_vShow, _ctx.canEdit]
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recruit.code_metas.location, function (location) {
                                            var _a;
                                            return (_openBlock(), _createElementBlock(_Fragment, {
                                                key: location.code
                                            }, [
                                                (location.code_data.active && location.code_data.parent)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                                                        _createElementVNode("span", null, [
                                                            _createTextVNode(_toDisplayString((_a = location.code_data.parent) === null || _a === void 0 ? void 0 : _a.lang_ko) + " > " + _toDisplayString(location.code_data.lang_ko), 1),
                                                            _cache[43] || (_cache[43] = _createElementVNode("span", { class: "slash" }, "/", -1))
                                                        ])
                                                    ]))
                                                    : _createCommentVNode("", true)
                                            ], 64));
                                        }), 128))
                                    ])
                                ]),
                                _createVNode(_component_SelectLocationModal, {
                                    ref: "applySelectedLocationModalRef",
                                    key: "select-location-modal",
                                    onSelectLocation: _ctx.applySelectedLocation,
                                    locations: _ctx.recruit.code_metas.location,
                                    options: _ctx.presetCodes.location,
                                    editable: _ctx.canEdit()
                                }, null, 8, ["onSelectLocation", "locations", "options", "editable"])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                            _cache[45] || (_cache[45] = _createElementVNode("label", { class: "col-form-label gray" }, [
                                _createTextVNode("근무지 사진"),
                                _createElementVNode("span", null, "(최대 5장)")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _createVNode(_component_ImageUploadComponent, {
                                    url: _ctx.imageUploadUrl,
                                    "max-files": 5,
                                    photos: _ctx.recruit.file_metas.company_photos,
                                    editable: _ctx.canEdit(),
                                    onChanged: _ctx.updateCompanyPhotos
                                }, null, 8, ["url", "photos", "editable", "onChanged"])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_27, [
                    _cache[52] || (_cache[52] = _createElementVNode("div", { class: "card-header d-flex flex-column justify-content-start" }, [
                        _createElementVNode("div", { class: "card-title" }, [
                            _createElementVNode("h5", null, "채용 담당자")
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_28, [
                        _cache[51] || (_cache[51] = _createElementVNode("small", null, "채용 담당자 연락처는 구직자에게 노출되지 않으며, 채용공고 지원시 잡플로이에서 지원알림을 위해서만 사용됩니다.", -1)),
                        _createElementVNode("div", _hoisted_29, [
                            _cache[48] || (_cache[48] = _createElementVNode("label", {
                                for: "contact_name",
                                class: "col-sm-2 col-form-label"
                            }, "담당자", -1)),
                            _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "form-control",
                                    "aria-label": "contact_name",
                                    name: "contact_name",
                                    id: "contact_name",
                                    placeholder: "담당자명을 입력해주세요",
                                    disabled: !_ctx.canEdit(),
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.contact_name) = $event); })
                                }, null, 8, _hoisted_30), [
                                    [_vModelText, _ctx.recruit.common_text_metas.meta.contact_name]
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                            _cache[49] || (_cache[49] = _createElementVNode("label", {
                                for: "contact_phone",
                                class: "col-form-label"
                            }, [
                                _createTextVNode("연락처"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "text",
                                    class: "form-control",
                                    "aria-label": "contact_phone",
                                    name: "contact_phone",
                                    id: "contact_phone",
                                    placeholder: "담당자 연락처를 입력해주세요",
                                    disabled: !_ctx.canEdit(),
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.contact_phone) = $event); })
                                }, null, 8, _hoisted_32), [
                                    [_vModelText, _ctx.recruit.common_text_metas.meta.contact_phone]
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_33, [
                            _cache[50] || (_cache[50] = _createElementVNode("label", {
                                for: "contact_mail",
                                class: "col-form-label"
                            }, [
                                _createTextVNode("이메일"),
                                _createElementVNode("span", null, "＊")
                            ], -1)),
                            _createElementVNode("div", null, [
                                _withDirectives(_createElementVNode("input", {
                                    type: "email",
                                    class: "form-control",
                                    "aria-label": "contact_mail",
                                    name: "contact_mail",
                                    id: "contact_mail",
                                    placeholder: "담당자 이메일을 입력해주세요",
                                    disabled: !_ctx.canEdit(),
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.contact_mail) = $event); })
                                }, null, 8, _hoisted_34), [
                                    [_vModelText, _ctx.recruit.common_text_metas.meta.contact_mail]
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_35, [
                    _cache[56] || (_cache[56] = _createElementVNode("div", { class: "card-header d-flex flex-column justify-content-start" }, [
                        _createElementVNode("div", { class: "card-title" }, [
                            _createElementVNode("h5", null, "외부 지원 설정")
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_36, [
                        _cache[55] || (_cache[55] = _createElementVNode("small", null, [
                            _createElementVNode("span", null, "*"),
                            _createTextVNode("별도의 외부 모집 페이지가 있을 경우 설정해주세요.")
                        ], -1)),
                        _createElementVNode("div", _hoisted_37, [
                            _cache[54] || (_cache[54] = _createElementVNode("label", {
                                for: "prefer_license",
                                class: "col-sm-2 col-form-label"
                            }, "홈페이지 지원 ", -1)),
                            _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_38, [
                                    _createElementVNode("div", _hoisted_39, [
                                        _createElementVNode("div", _hoisted_40, [
                                            _withDirectives(_createElementVNode("input", {
                                                type: "checkbox",
                                                class: "form-check-input mt-0",
                                                name: "use_action_site",
                                                value: true,
                                                uncheckedValue: false,
                                                id: "use_action_site",
                                                disabled: !_ctx.canEdit(),
                                                "onUpdate:modelValue": _cache[13] || (_cache[13] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.use_action_site) = $event); })
                                            }, null, 8, _hoisted_41), [
                                                [_vModelCheckbox, _ctx.recruit.common_text_metas.meta.use_action_site]
                                            ]),
                                            _cache[53] || (_cache[53] = _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: "use_action_site"
                                            }, "설정", -1))
                                        ])
                                    ]),
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        name: "action_phone",
                                        placeholder: "홈페이지 링크를 입력해주세요",
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = function ($event) { return ((_ctx.recruit.common_text_metas.meta.action_site) = $event); }),
                                        disabled: !(_ctx.recruit.common_text_metas.meta.use_action_site && _ctx.canEdit()),
                                        readonly: !(_ctx.recruit.common_text_metas.meta.use_action_site && _ctx.canEdit())
                                    }, null, 8, _hoisted_42), [
                                        [_vModelText, _ctx.recruit.common_text_metas.meta.action_site]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _cache[95] || (_cache[95] = _createElementVNode("div", { class: "w-100" }, [
                    _createElementVNode("p", { class: "info-text" }, "+ 아래 선택정보를 입력하시면, 더 적합한 인재를 추천받을 수 있습니다.")
                ], -1)),
                _createElementVNode("div", _hoisted_43, [
                    _cache[69] || (_cache[69] = _createElementVNode("div", {
                        id: "headingOne",
                        class: "card-header"
                    }, [
                        _createElementVNode("button", {
                            class: "d-flex justify-content-between w-100 collapsed",
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#collapseOne",
                            "aria-expanded": "true",
                            "aria-controls": "collapseOne"
                        }, [
                            _createElementVNode("div", { class: "card-title" }, [
                                _createElementVNode("h5", null, [
                                    _createElementVNode("span", null, "(선택)"),
                                    _createTextVNode("근무정보")
                                ])
                            ]),
                            _createElementVNode("i", { class: "bi bi-chevron-up" })
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_44, [
                        _createElementVNode("div", _hoisted_45, [
                            _cache[68] || (_cache[68] = _createElementVNode("small", null, "근무 정보를 입력해주세요.", -1)),
                            _createElementVNode("div", _hoisted_46, [
                                _cache[59] || (_cache[59] = _createElementVNode("label", { class: "col-form-label" }, "직무", -1)),
                                _createElementVNode("div", null, [
                                    _createElementVNode("div", _hoisted_47, [
                                        _createElementVNode("button", {
                                            class: "search_btn btn btn-primary",
                                            type: "button",
                                            id: "button-addon1",
                                            onClick: _cache[15] || (_cache[15] = function ($event) { return (_ctx.openModal('selectTaskModalRef')); })
                                        }, _cache[57] || (_cache[57] = [
                                            _createElementVNode("i", { class: "bi bi-search" }, null, -1),
                                            _createTextVNode(" 직무 선택 ")
                                        ])),
                                        _createElementVNode("div", _hoisted_48, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recruit.code_metas.task, function (task) {
                                                var _a;
                                                return (_openBlock(), _createElementBlock(_Fragment, {
                                                    key: task.code
                                                }, [
                                                    (task.code_data.active)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_49, [
                                                            _createElementVNode("span", null, [
                                                                _createTextVNode(_toDisplayString((_a = task.code_data.parent) === null || _a === void 0 ? void 0 : _a.lang_ko) + " > " + _toDisplayString(task.code_data.lang_ko), 1),
                                                                _cache[58] || (_cache[58] = _createElementVNode("span", { class: "slash" }, "/", -1))
                                                            ])
                                                        ]))
                                                        : _createCommentVNode("", true)
                                                ], 64));
                                            }), 128))
                                        ])
                                    ])
                                ]),
                                _createVNode(_component_SelectTaskModal, {
                                    ref: "selectTaskModalRef",
                                    tasks: _ctx.recruit.code_metas.task,
                                    options: _ctx.presetCodes.task,
                                    editable: _ctx.canEdit(),
                                    onConfirmOptions: _ctx.applySelectedTask
                                }, null, 8, ["tasks", "options", "editable", "onConfirmOptions"])
                            ]),
                            _createElementVNode("div", _hoisted_50, [
                                _cache[60] || (_cache[60] = _createElementVNode("label", { class: "col-form-label" }, "고용형태", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "work_type",
                                        options: _ctx.presetCodes.work_type,
                                        metas: _ctx.recruit.code_metas.work_type,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "work_type_extra",
                                            name: "work_type_extra",
                                            id: "work_type_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[16] || (_cache[16] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_type_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.work_type_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_51, [
                                _cache[61] || (_cache[61] = _createElementVNode("label", { class: "col-form-label" }, "근무기간", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "work_duration",
                                        options: _ctx.presetCodes.work_duration,
                                        metas: _ctx.recruit.code_metas.work_duration,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "work_duration_extra",
                                            name: "work_duration_extra",
                                            id: "work_duration_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[17] || (_cache[17] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_duration_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.work_duration_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_52, [
                                _cache[63] || (_cache[63] = _createElementVNode("label", { class: "col-form-label" }, "근무요일", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "work_date",
                                        options: _ctx.presetCodes.work_date,
                                        metas: _ctx.recruit.code_metas.work_date,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _cache[62] || (_cache[62] = _createElementVNode("div", null, null, -1)),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "work_date_extra",
                                            name: "work_date_extra",
                                            id: "work_date_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_date_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.work_date_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_53, [
                                _cache[64] || (_cache[64] = _createElementVNode("label", {
                                    for: "work_time",
                                    class: "col-form-label"
                                }, "근무시간", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "work_time",
                                        name: "work_time",
                                        id: "work_time",
                                        placeholder: "근무시간을 입력해주세요",
                                        "onUpdate:modelValue": _cache[19] || (_cache[19] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_time) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.work_time]
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_54, [
                                _cache[65] || (_cache[65] = _createElementVNode("label", {
                                    for: "worktime",
                                    class: "col-form-label"
                                }, "급여", -1)),
                                _createElementVNode("div", _hoisted_55, [
                                    _createVNode(_component_PaytypeInput, {
                                        paytype: _ctx.recruit.code_metas.work_paytype,
                                        payment: _ctx.recruit.common_text_metas.meta.work_payment,
                                        options: _ctx.presetCodes.work_paytype,
                                        editable: _ctx.canEdit(),
                                        onUpdatePaytype: _ctx.updatePaytype,
                                        onUpdatePayment: _ctx.updatePayment
                                    }, null, 8, ["paytype", "payment", "options", "editable", "onUpdatePaytype", "onUpdatePayment"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "work_paytype_extra",
                                            name: "work_paytype_extra",
                                            id: "work_paytype_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            disabled: !_ctx.canEdit(),
                                            "onUpdate:modelValue": _cache[20] || (_cache[20] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_paytype_extra) = $event); })
                                        }, null, 8, _hoisted_56), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.work_paytype_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_57, [
                                _cache[66] || (_cache[66] = _createElementVNode("label", {
                                    for: "work_etc",
                                    class: "col-form-label"
                                }, "근무조건", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "work_etc",
                                        name: "work_etc",
                                        id: "work_etc",
                                        placeholder: "근무조건을 입력해주세요",
                                        "onUpdate:modelValue": _cache[21] || (_cache[21] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_etc) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.work_etc]
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_58, [
                                _cache[67] || (_cache[67] = _createElementVNode("label", {
                                    for: "work_overview",
                                    class: "col-form-label"
                                }, "담당업무", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "work_overview",
                                        name: "work_overview",
                                        id: "work_overview",
                                        placeholder: "추가내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[22] || (_cache[22] = function ($event) { return ((_ctx.recruit.text_metas.meta.work_overview) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.work_overview]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_59, [
                    _cache[78] || (_cache[78] = _createElementVNode("div", {
                        id: "headingTwo",
                        class: "card-header"
                    }, [
                        _createElementVNode("button", {
                            class: "d-flex justify-content-between w-100 collapsed",
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#collapseTwo",
                            "aria-expanded": "true",
                            "aria-controls": "collapseTwo"
                        }, [
                            _createElementVNode("div", { class: "card-title" }, [
                                _createElementVNode("h5", null, [
                                    _createElementVNode("span", null, "(선택)"),
                                    _createTextVNode("복리후생")
                                ])
                            ]),
                            _createElementVNode("i", { class: "bi bi-chevron-up" })
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_60, [
                        _createElementVNode("div", _hoisted_61, [
                            _cache[77] || (_cache[77] = _createElementVNode("small", null, "복리후생 정보를 입력해주세요.", -1)),
                            _createElementVNode("div", _hoisted_62, [
                                _cache[70] || (_cache[70] = _createElementVNode("label", { class: "col-form-label" }, "4대 보험", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_insurance",
                                        options: _ctx.presetCodes.welfare_insurance,
                                        metas: _ctx.recruit.code_metas.welfare_insurance,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_63, [
                                _cache[71] || (_cache[71] = _createElementVNode("label", { class: "col-form-label" }, "식사/식대", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_meals",
                                        options: _ctx.presetCodes.welfare_meals,
                                        metas: _ctx.recruit.code_metas.welfare_meals,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_64, [
                                _cache[72] || (_cache[72] = _createElementVNode("label", { class: "col-form-label" }, "교통편", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_trans",
                                        options: _ctx.presetCodes.welfare_trans,
                                        metas: _ctx.recruit.code_metas.welfare_trans,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_65, [
                                _cache[73] || (_cache[73] = _createElementVNode("label", { class: "col-form-label" }, "숙소", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_lodging",
                                        options: _ctx.presetCodes.welfare_lodging,
                                        metas: _ctx.recruit.code_metas.welfare_lodging,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_66, [
                                _cache[74] || (_cache[74] = _createElementVNode("label", { class: "col-form-label" }, "보너스", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_bonus",
                                        options: _ctx.presetCodes.welfare_bonus,
                                        metas: _ctx.recruit.code_metas.welfare_bonus,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_67, [
                                _cache[75] || (_cache[75] = _createElementVNode("label", { class: "col-form-label" }, "휴가", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "welfare_vacation",
                                        options: _ctx.presetCodes.welfare_vacation,
                                        metas: _ctx.recruit.code_metas.welfare_vacation,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_68, [
                                _cache[76] || (_cache[76] = _createElementVNode("label", { class: "col-form-label" }, "기타", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "welfare_etc",
                                        name: "welfare_etc",
                                        id: "welfare_etc",
                                        placeholder: "추가내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[23] || (_cache[23] = function ($event) { return ((_ctx.recruit.text_metas.meta.welfare_etc) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.welfare_etc]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_69, [
                    _cache[88] || (_cache[88] = _createElementVNode("div", {
                        id: "headingThree",
                        class: "card-header"
                    }, [
                        _createElementVNode("button", {
                            class: "d-flex justify-content-between w-100 collapsed",
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#collapseThree",
                            "aria-expanded": "true",
                            "aria-controls": "collapseThree"
                        }, [
                            _createElementVNode("div", { class: "card-title" }, [
                                _createElementVNode("h5", null, [
                                    _createElementVNode("span", null, "(선택)"),
                                    _createTextVNode("지원조건, 비자")
                                ])
                            ]),
                            _createElementVNode("i", { class: "bi bi-chevron-up" })
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, [
                            _cache[87] || (_cache[87] = _createElementVNode("small", null, "지원 조건을 입력해주세요.", -1)),
                            _createElementVNode("div", _hoisted_72, [
                                _cache[79] || (_cache[79] = _createElementVNode("label", { class: "col-form-label" }, "필요문서", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_docu",
                                        options: _ctx.presetCodes.con_docu,
                                        metas: _ctx.recruit.code_metas.con_docu,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "con_docu_extra",
                                            name: "con_docu_extra",
                                            id: "con_docu_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[24] || (_cache[24] = function ($event) { return ((_ctx.recruit.text_metas.meta.con_docu_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.con_docu_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_73, [
                                _cache[80] || (_cache[80] = _createElementVNode("label", { class: "col-form-label" }, "비자", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_visa",
                                        options: _ctx.presetCodes.con_visa,
                                        metas: _ctx.recruit.code_metas.con_visa,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_74, [
                                _cache[81] || (_cache[81] = _createElementVNode("label", { class: "col-form-label" }, "언어능력", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_language",
                                        options: _ctx.presetCodes.con_language,
                                        metas: _ctx.recruit.code_metas.con_language,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "con_language_extra",
                                            name: "con_language_extra",
                                            id: "con_language_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[25] || (_cache[25] = function ($event) { return ((_ctx.recruit.text_metas.meta.con_language_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.con_language_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_75, [
                                _cache[82] || (_cache[82] = _createElementVNode("label", {
                                    for: "career",
                                    class: "col-form-label"
                                }, "동일직무 경력", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "career",
                                        name: "career",
                                        id: "career",
                                        placeholder: "추가내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[26] || (_cache[26] = function ($event) { return ((_ctx.recruit.text_metas.meta.career) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.career]
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_76, [
                                _cache[83] || (_cache[83] = _createElementVNode("label", { class: "col-form-label" }, "성별", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_sex",
                                        options: _ctx.presetCodes.con_sex,
                                        metas: _ctx.recruit.code_metas.con_sex,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"]),
                                    _createElementVNode("div", null, [
                                        _withDirectives(_createElementVNode("input", {
                                            type: "text",
                                            class: "form-control",
                                            "aria-label": "con_sex_extra",
                                            name: "con_sex_extra",
                                            id: "con_sex_extra",
                                            placeholder: "추가내용을 입력해주세요",
                                            "onUpdate:modelValue": _cache[27] || (_cache[27] = function ($event) { return ((_ctx.recruit.text_metas.meta.con_sex_extra) = $event); })
                                        }, null, 512), [
                                            [_vModelText, _ctx.recruit.text_metas.meta.con_sex_extra]
                                        ])
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_77, [
                                _cache[84] || (_cache[84] = _createElementVNode("label", { class: "col-form-label" }, "연령대", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_age",
                                        options: _ctx.presetCodes.con_age,
                                        metas: _ctx.recruit.code_metas.con_age,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_78, [
                                _cache[85] || (_cache[85] = _createElementVNode("label", { class: "col-form-label" }, "학력", -1)),
                                _createElementVNode("div", null, [
                                    _createVNode(_component_CodeMetaCheckBoxList, {
                                        type: "con_edu",
                                        options: _ctx.presetCodes.con_edu,
                                        metas: _ctx.recruit.code_metas.con_edu,
                                        editable: _ctx.canEdit(),
                                        onUpdateCheckedCode: _ctx.applyCheckedCode,
                                        class: "checkbox_group"
                                    }, null, 8, ["options", "metas", "editable", "onUpdateCheckedCode"])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_79, [
                                _cache[86] || (_cache[86] = _createElementVNode("label", {
                                    for: "con_etc",
                                    class: "col-form-label"
                                }, "기타", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "con_etc",
                                        name: "con_etc",
                                        id: "con_etc",
                                        placeholder: "내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[28] || (_cache[28] = function ($event) { return ((_ctx.recruit.text_metas.meta.con_etc) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.con_etc]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_80, [
                    _cache[93] || (_cache[93] = _createElementVNode("div", {
                        id: "headingFour",
                        class: "card-header"
                    }, [
                        _createElementVNode("button", {
                            class: "d-flex justify-content-between w-100 collapsed",
                            type: "button",
                            "data-bs-toggle": "collapse",
                            "data-bs-target": "#collapseFour",
                            "aria-expanded": "true",
                            "aria-controls": "collapseFour"
                        }, [
                            _createElementVNode("div", { class: "card-title" }, [
                                _createElementVNode("h5", null, [
                                    _createElementVNode("span", null, "(선택)"),
                                    _createTextVNode("우대조건")
                                ])
                            ]),
                            _createElementVNode("i", { class: "bi bi-chevron-up" })
                        ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_81, [
                        _createElementVNode("div", _hoisted_82, [
                            _cache[92] || (_cache[92] = _createElementVNode("small", null, [
                                _createElementVNode("span", null, "*"),
                                _createTextVNode("우대 조건이 있을 경우에만 입력해주세요 ")
                            ], -1)),
                            _createElementVNode("div", _hoisted_83, [
                                _cache[89] || (_cache[89] = _createElementVNode("label", { class: "col-form-label" }, "우대국가", -1)),
                                _createElementVNode("div", null, [
                                    (!_ctx.isMobile)
                                        ? (_openBlock(), _createBlock(_component_CodeMetaFilter, {
                                            key: 0,
                                            type: "info_country",
                                            options: _ctx.presetCodes.info_country,
                                            onUpdateCheckedCode: _ctx.applyCheckedCode,
                                            class: "checkbox_group"
                                        }, null, 8, ["options", "onUpdateCheckedCode"]))
                                        : (_openBlock(), _createBlock(_component_MobileCodeMetaFilter, {
                                            key: 1,
                                            type: "info_country",
                                            options: _ctx.presetCodes.info_country,
                                            onUpdateCheckedCode: _ctx.applyCheckedCode,
                                            class: "checkbox_group"
                                        }, null, 8, ["options", "onUpdateCheckedCode"]))
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_84, [
                                _cache[90] || (_cache[90] = _createElementVNode("label", {
                                    for: "prefer_license",
                                    class: "col-form-label license"
                                }, [
                                    _createTextVNode("보유스킬"),
                                    _createElementVNode("span", null, "(자격증)")
                                ], -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "prefer_license",
                                        name: "prefer_license",
                                        id: "prefer_license",
                                        placeholder: "내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[29] || (_cache[29] = function ($event) { return ((_ctx.recruit.text_metas.meta.prefer_license) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.prefer_license]
                                    ])
                                ])
                            ]),
                            _createElementVNode("div", _hoisted_85, [
                                _cache[91] || (_cache[91] = _createElementVNode("label", {
                                    for: "prefer_etc",
                                    class: "col-form-label"
                                }, "기타 ", -1)),
                                _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("input", {
                                        type: "text",
                                        class: "form-control",
                                        "aria-label": "prefer_etc",
                                        name: "prefer_etc",
                                        id: "prefer_etc",
                                        placeholder: "내용을 입력해주세요",
                                        "onUpdate:modelValue": _cache[30] || (_cache[30] = function ($event) { return ((_ctx.recruit.text_metas.meta.prefer_etc) = $event); })
                                    }, null, 512), [
                                        [_vModelText, _ctx.recruit.text_metas.meta.prefer_etc]
                                    ])
                                ])
                            ])
                        ])
                    ])
                ]),
                _cache[96] || (_cache[96] = _createElementVNode("button", {
                    class: "large_btn blue_btn mobile_submit_btn",
                    type: "submit"
                }, "저장", -1))
            ], 544)
        ])
    ]));
}
