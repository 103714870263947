import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "d-flex filters" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_recruit_etc_filter = _resolveComponent("recruit-etc-filter");
    var _component_recruit_filter = _resolveComponent("recruit-filter");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createElementVNode("div", {
            class: "filter-backdrop",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return (_ctx.closeFilter && _ctx.closeFilter.apply(_ctx, args));
                })
        }, null, 512), [
            [_vShow, _ctx.showBackdrop]
        ]),
        _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_recruit_etc_filter, {
                class: "etc",
                ref: "etcRef",
                titles: _ctx.titles,
                "request-codes": _ctx.requestEtcCodes,
                "request-categories": _ctx.requestEtcCategories,
                locales: _ctx.locales,
                onFilterToggleEvent: _ctx.toggleUnusedUI
            }, null, 8, ["titles", "request-codes", "request-categories", "locales", "onFilterToggleEvent"]),
            _createVNode(_component_recruit_filter, {
                ref: "taskRef",
                type: "task",
                title: _ctx.titles.task,
                "request-codes": _ctx.requestTaskCodes,
                "request-categories": _ctx.requestTaskCategories,
                locales: _ctx.locales,
                onFilterToggleEvent: _ctx.toggleUnusedUI
            }, null, 8, ["title", "request-codes", "request-categories", "locales", "onFilterToggleEvent"]),
            _createVNode(_component_recruit_filter, {
                ref: "locationRef",
                type: "location",
                title: _ctx.titles.location,
                "request-codes": _ctx.requestLocationCodes,
                "request-categories": _ctx.requestLocationCategories,
                locales: _ctx.locales,
                onFilterToggleEvent: _ctx.toggleUnusedUI
            }, null, 8, ["title", "request-codes", "request-categories", "locales", "onFilterToggleEvent"])
        ])
    ], 64));
}
