import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
var _hoisted_1 = {
    class: "modal fade",
    tabindex: "-1",
    "aria-hidden": "true",
    ref: "selectOptionModalRef"
};
var _hoisted_2 = { class: "modal-dialog" };
var _hoisted_3 = { class: "modal-content mobile-modal" };
var _hoisted_4 = { class: "modal-body filterUI" };
var _hoisted_5 = { class: "row filterUI-body" };
var _hoisted_6 = { class: "col-4 border-end h-100" };
var _hoisted_7 = { class: "filterUI-body-left list-group list-group-flush filterUI-body-category" };
var _hoisted_8 = ["onClick"];
var _hoisted_9 = { class: "filterUI-body-right col h-100" };
var _hoisted_10 = { class: "row filterUI-body-list" };
var _hoisted_11 = ["onClick"];
var _hoisted_12 = ["onClick"];
var _hoisted_13 = { class: "modal-footer row filterUI-footer" };
var _hoisted_14 = { class: "select-title" };
var _hoisted_15 = { class: "result d-flex" };
var _hoisted_16 = { class: "d-flex align-items-center flex-wrap" };
var _hoisted_17 = {
    key: 0,
    class: "text-info d-flex align-items-center"
};
var _hoisted_18 = ["onClick"];
var _hoisted_19 = { class: "btns d-flex justify-content-end py-3 px-4" };
var _hoisted_20 = { class: "d-flex align-items-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "modal-header justify-content-between" }, [
                    _createElementVNode("span", null, "근무지"),
                    _createElementVNode("div", { "data-bs-dismiss": "modal" }, [
                        _createElementVNode("span", { class: "close-icon" }, [
                            _createElementVNode("i", { class: "bi bi-x" })
                        ])
                    ])
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, function (Option) {
                                    return (_openBlock(), _createElementBlock("button", {
                                        type: "button",
                                        class: _normalizeClass(["list-group-item list-group-item-action", { active: _ctx.isactivedOption(Option) }]),
                                        key: Option.code,
                                        onClick: function ($event) { return (_ctx.showSuboptions(Option)); }
                                    }, [
                                        _createElementVNode("span", {
                                            class: _normalizeClass({ select: _ctx.hasSelectedCode(Option) })
                                        }, _toDisplayString(Option.lang_ko), 3),
                                        _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-chevron-right" }, null, -1))
                                    ], 10, _hoisted_8));
                                }), 128))
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                                (_ctx.activedOption)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.activedOption.children, function (option) {
                                        return (_openBlock(), _createElementBlock("div", {
                                            class: "filterUI-body-list-item",
                                            key: option.code
                                        }, [
                                            _withDirectives(_createElementVNode("div", null, [
                                                _withDirectives(_createElementVNode("button", {
                                                    type: "button",
                                                    onClick: function ($event) { _ctx.selectSubOption(_ctx.activedOption, option); _ctx.createSelectedChildrenArray(); }
                                                }, [
                                                    _createElementVNode("span", null, _toDisplayString(option.lang_ko), 1)
                                                ], 8, _hoisted_11), [
                                                    [_vShow, !option.active]
                                                ]),
                                                _withDirectives(_createElementVNode("button", {
                                                    class: "select",
                                                    type: "button",
                                                    onClick: function ($event) { _ctx.removeSubOption(_ctx.activedOption, option); _ctx.removeSelectedChildren(); }
                                                }, [
                                                    _createElementVNode("span", null, _toDisplayString(option.lang_ko), 1)
                                                ], 8, _hoisted_12), [
                                                    [_vShow, option.active]
                                                ])
                                            ], 512), [
                                                [_vShow, _ctx.editable]
                                            ])
                                        ]));
                                    }), 128))
                                    : _createCommentVNode("", true)
                            ])
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                        _cache[3] || (_cache[3] = _createTextVNode(" 선택한 직무 ")),
                        _createElementVNode("span", null, _toDisplayString(_ctx.selectedCodes.length), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, function (option) {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                    key: option.code
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.children, function (subOption) {
                                        return (_openBlock(), _createElementBlock(_Fragment, {
                                            key: subOption.code
                                        }, [
                                            (subOption.active)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                                                    _createElementVNode("span", null, _toDisplayString(option.lang_ko) + " > " + _toDisplayString(subOption.lang_ko), 1),
                                                    _createElementVNode("button", {
                                                        class: "btn-close btn btn-sm",
                                                        type: "button",
                                                        "aria-label": "Close",
                                                        onClick: function ($event) { _ctx.removeSubOption(_ctx.activedOption, subOption); _ctx.removeSelectedChildren(); }
                                                    }, _cache[4] || (_cache[4] = [
                                                        _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                                    ]), 8, _hoisted_18)
                                                ]))
                                                : _createCommentVNode("", true)
                                        ], 64));
                                    }), 128))
                                ], 64));
                            }), 128))
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("button", {
                                class: "btn",
                                type: "button",
                                onClick: _cache[0] || (_cache[0] = function ($event) { _ctx.resetFilter(); })
                            }, _cache[5] || (_cache[5] = [
                                _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1),
                                _createTextVNode("초기화")
                            ])),
                            _createElementVNode("button", {
                                class: "btn btn-primary",
                                type: "button",
                                onClick: _cache[1] || (_cache[1] = function ($event) { return (_ctx.apply()); })
                            }, "적용")
                        ])
                    ])
                ])
            ])
        ])
    ], 512));
}
