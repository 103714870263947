import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
    class: "interest_filter d-flex flex-column w-100",
    ref: "filterContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_resume_filter = _resolveComponent("resume-filter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_resume_filter, {
            ref: "".concat(_ctx.filtertype, "Ref"),
            type: _ctx.filtertype,
            locales: _ctx.locales,
            options: _ctx.options,
            initValue: _ctx.initValue,
            "request-categories": _ctx.getResumeFilterCategories(_ctx.filtertype),
            "filter-visible": _ctx.activeFilter === _ctx.filtertype,
            onFilterToggleEvent: _ctx.handleFilterToggle
        }, null, 8, ["type", "locales", "options", "initValue", "request-categories", "filter-visible", "onFilterToggleEvent"])
    ], 512));
}
