var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vShow as _vShow } from "vue";
var _hoisted_1 = { class: "position-relative" };
var _hoisted_2 = { class: "select_box" };
var _hoisted_3 = { class: "text-truncate d-flex flex-wrap fs-6.5" };
var _hoisted_4 = {
    key: 0,
    class: "hint"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
    key: 0,
    class: "popup_background"
};
var _hoisted_7 = ["id"];
var _hoisted_8 = { class: "filterUI-title" };
var _hoisted_9 = { class: "title-line d-flex justify-content-between align-items-center" };
var _hoisted_10 = { class: "row filterUI-body" };
var _hoisted_11 = { class: "filterUI-body-right col h-100" };
var _hoisted_12 = { class: "row filterUI-body-list" };
var _hoisted_13 = { class: "form-check" };
var _hoisted_14 = ["id", "value"];
var _hoisted_15 = ["for"];
var _hoisted_16 = { class: "row filterUI-footer" };
var _hoisted_17 = { class: "select_title" };
var _hoisted_18 = { class: "result d-flex flex-wrap" };
var _hoisted_19 = ["name", "value"];
var _hoisted_20 = { class: "text-info mx-3" };
var _hoisted_21 = ["onClick"];
var _hoisted_22 = { class: "btns d-flex justify-content-end px-4 py-3" };
var _hoisted_23 = { class: "d-flex flex-sm-row align-items-center select-btns" };
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
// Props 정의
export default /*@__PURE__*/ _defineComponent({
    __name: 'MobileResumeFilter',
    props: {
        options: {
            required: true,
            type: (Array),
        },
        initValue: {
            required: false,
            type: [String, Array],
            default: function () { return []; },
        },
        type: {
            required: true,
            type: String,
        },
        filterVisible: {
            required: true,
            type: Boolean,
        },
        locales: {
            required: true,
            type: Object,
        },
        filterTitle: {
            required: true,
            type: String
        },
    },
    emits: ['filter-toggle-event', 'update:initValue'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        // Emits 정의
        var emit = __emit;
        // Reactive 데이터
        var lang = ref(document.documentElement.lang);
        var selectedCategory = ref([]); // 최종 저장소
        var pendingCategory = ref([]); // 임시 저장소
        // Methods
        var syncSelectedCategory = function (newInitValue) {
            if (!props.options || props.options.length === 0)
                return;
            if (Array.isArray(newInitValue)) {
                selectedCategory.value = props.options.filter(function (option) {
                    return newInitValue.includes(option.code);
                });
                pendingCategory.value = __spreadArray([], selectedCategory.value, true);
            }
            else if (typeof newInitValue === 'string') {
                var selectedOption = props.options.find(function (option) { return option.code === newInitValue; });
                selectedCategory.value = selectedOption ? [selectedOption] : [];
                pendingCategory.value = __spreadArray([], selectedCategory.value, true);
            }
            else {
                selectedCategory.value = [];
                pendingCategory.value = [];
            }
        };
        var updateInitValue = function (newSelectedCategory) {
            var selectedCodes = newSelectedCategory.map(function (category) { return category.code; });
            emit('update:initValue', selectedCodes);
        };
        var toggleFilterPopup = function () {
            var isNowOpen = !props.filterVisible;
            emit('filter-toggle-event', props.type, isNowOpen);
            if (isNowOpen) {
                history.pushState({ popupOpen: true }, 'filter', '#filter');
                document.body.style.overflow = 'hidden';
            }
            else {
                if (window.location.hash === '#filter') {
                    history.back();
                }
                document.body.style.overflow = '';
            }
        };
        var initPendingCategory = function () {
            pendingCategory.value = [];
            pendingCategory.value = __spreadArray([], selectedCategory.value, true);
        };
        var closeFilterPopup = function () {
            pendingCategory.value = __spreadArray([], selectedCategory.value, true);
            emit('filter-toggle-event', props.type, false);
            if (window.location.hash === '#filter') {
                history.back();
            }
            document.body.style.overflow = '';
        };
        var removeSelectedCategory = function (code, event) {
            event.stopPropagation(); // 이벤트 전파 방지
            selectedCategory.value = selectedCategory.value.filter(function (selected) { return selected.code !== code.code; });
            pendingCategory.value = pendingCategory.value.filter(function (pending) { return pending.code !== code.code; });
        };
        var resetFilter = function () {
            pendingCategory.value = [];
        };
        var applyPendingCategory = function () {
            selectedCategory.value = __spreadArray([], pendingCategory.value, true); // 최종 선택 반영
            closeFilterPopup();
        };
        var handlePopState = function () {
            if (window.location.hash !== '#filter') {
                emit('filter-toggle-event', props.type, false);
                document.body.style.overflow = '';
            }
        };
        // 브라우저 뒤로 가기 이벤트 리스너 등록
        onMounted(function () {
            window.addEventListener('popstate', handlePopState);
        });
        // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
        onBeforeUnmount(function () {
            window.removeEventListener('popstate', handlePopState);
        });
        // Watchers
        watch([function () { return props.initValue; }, function () { return props.options; }], function (_a) {
            var newInitValue = _a[0], newOptions = _a[1];
            if (newOptions && newOptions.length > 0) {
                // 약간의 딜레이 추가
                setTimeout(function () {
                    syncSelectedCategory(newInitValue);
                }, 100);
            }
        }, { immediate: true });
        watch(selectedCategory, function (newSelectedCategory) {
            updateInitValue(newSelectedCategory);
        }, { deep: true });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: "dropdown-toggle position-relative",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return (toggleFilterPopup()); })
                    }, [
                        _createElementVNode("div", _hoisted_3, [
                            (selectedCategory.value.length === 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(__props.type === 'task' ? __props.locales.common_jobhint : (__props.type === 'location' ? __props.locales.common_locationhint : '')), 1))
                                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(selectedCategory.value, function (code) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: 'title_' + code.code,
                                        class: "selected_code"
                                    }, [
                                        _createElementVNode("span", null, _toDisplayString(code['lang_' + lang.value]), 1),
                                        _createElementVNode("button", {
                                            class: "btn btn-sm",
                                            type: "button",
                                            "aria-label": "Close",
                                            onClick: function ($event) { return (removeSelectedCategory(code, $event)); }
                                        }, _cache[6] || (_cache[6] = [
                                            _createElementVNode("i", { class: "bi bi-x-lg" }, null, -1)
                                        ]), 8, _hoisted_5)
                                    ]));
                                }), 128))
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("button", { type: "button" }, [
                            _createElementVNode("i", { class: "bi bi-chevron-down" })
                        ], -1))
                    ])
                ]),
                _createElementVNode("div", {
                    class: "back_wrap",
                    onClick: _cache[1] || (_cache[1] = function ($event) { return ([toggleFilterPopup(), initPendingCategory()]); })
                }, [
                    _createVNode(_Transition, null, {
                        default: _withCtx(function () { return [
                            (__props.filterVisible)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                                : _createCommentVNode("", true)
                        ]; }),
                        _: 1
                    })
                ]),
                _withDirectives(_createElementVNode("div", {
                    class: "filterUI",
                    id: __props.type + 'Filter'
                }, [
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(__props.filterTitle), 1),
                            _createElementVNode("span", {
                                class: "close_btn",
                                onClick: _cache[2] || (_cache[2] = function ($event) { return ([toggleFilterPopup(), initPendingCategory()]); })
                            }, _cache[8] || (_cache[8] = [
                                _createElementVNode("i", { class: "bi bi-x" }, null, -1)
                            ]))
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, function (category) {
                                    return (_openBlock(), _createElementBlock("div", {
                                        class: "filterUI-body-list-item",
                                        key: category.code
                                    }, [
                                        _createElementVNode("div", _hoisted_13, [
                                            _withDirectives(_createElementVNode("input", {
                                                class: "form-check-input",
                                                id: category.code,
                                                type: "checkbox",
                                                value: category,
                                                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) { return ((pendingCategory).value = $event); })
                                            }, null, 8, _hoisted_14), [
                                                [_vModelCheckbox, pendingCategory.value]
                                            ]),
                                            _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: category.code
                                            }, _toDisplayString(category['lang_' + lang.value]), 9, _hoisted_15)
                                        ])
                                    ]));
                                }), 128))
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                            _createTextVNode(_toDisplayString(__props.locales.common_selected) + " " + _toDisplayString(__props.locales.common_filter) + " ", 1),
                            _createElementVNode("span", null, _toDisplayString(pendingCategory.value.length), 1)
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("input", {
                                    key: 'hidden_' + code.code,
                                    type: "hidden",
                                    name: "".concat(__props.type, "[]"),
                                    value: code.code
                                }, null, 8, _hoisted_19));
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pendingCategory.value, function (code) {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: 'selected_' + code.code,
                                    class: "d-flex align-items-center"
                                }, [
                                    (pendingCategory.value.length > 0)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createElementVNode("span", _hoisted_20, _toDisplayString(code['lang_' + lang.value]), 1),
                                            _createElementVNode("button", {
                                                class: "btn-close btn btn-sm",
                                                type: "button",
                                                "aria-label": "Close",
                                                onClick: function ($event) { return (removeSelectedCategory(code, $event)); }
                                            }, _cache[9] || (_cache[9] = [
                                                _createElementVNode("i", { class: "bi bi-x-circle-fill" }, null, -1)
                                            ]), 8, _hoisted_21)
                                        ], 64))
                                        : _createCommentVNode("", true)
                                ]));
                            }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("button", {
                                    class: "btn btn-sm py-0",
                                    type: "button",
                                    onClick: _cache[4] || (_cache[4] = function ($event) { return (resetFilter()); })
                                }, [
                                    _createTextVNode(_toDisplayString(__props.locales.common_reset), 1),
                                    _cache[10] || (_cache[10] = _createElementVNode("i", { class: "bi bi-arrow-counterclockwise" }, null, -1))
                                ]),
                                _createElementVNode("button", {
                                    onClick: _cache[5] || (_cache[5] = function ($event) { return (applyPendingCategory()); }),
                                    class: "btn btn-primary text-white",
                                    type: "button"
                                }, _toDisplayString(__props.locales.common_set), 1)
                            ])
                        ])
                    ])
                ], 8, _hoisted_7), [
                    [_vShow, __props.filterVisible]
                ])
            ]));
        };
    }
});
